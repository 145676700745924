import React, {useState} from "react";
import {TextField, TextFieldProps} from "./TextField";
import {IconEye, IconEyeOff} from "../../icons/ActionIcons";

type Props = {
    onForgotPasswordClick: () => void;
} & TextFieldProps;

export function PasswordField({onForgotPasswordClick, ...pp}: Props) {
    const [isPasswordType, setIsPasswordType] = useState<boolean>(true);
    return (
        <TextField
            inputType={isPasswordType ? "password" : "text"}
            icon={isPasswordType ? <IconEyeOff/> : <IconEye/>}
            onIconClick={() => setIsPasswordType(!isPasswordType)}
            additionalBlock={
                <div className="password-additional-block margin-t-8 flex-row flex-j-end">
                    <div onClick={onForgotPasswordClick}>Забыли пароль?</div>
                </div>
            }
            {...pp}
        />
    )
}