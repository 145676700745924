import React, {useContext} from "react";
import {Button, ButtonProps, EButtonSize, EButtonStyle} from "../../components/controls/Button/Button";
import {generateUuid} from "../Utils";
import {MasterContext} from "./context/MasterContext";
import {useNavigate} from "react-router-dom";
import {MasterAccumulatorInterface} from "./context/@types/MasterContext";
import {H3} from "../../components/text/Header/Header";
import {TextL} from "../../components/text/Text/Text";
import {IconErrorCircleColor} from "../../components/icons/InfoIcons";

export type MasterStepProps = {
    caption: React.ReactNode;
    body?: React.ReactNode;
    buttons?: Array<ButtonProps>;
    isResultStep?: boolean;
};

export const MasterStep = React.memo(function MasterStep({body, buttons, isResultStep = false}: MasterStepProps) {
    const {openPreviousStep, openNextStep} = useContext(MasterContext);
    const navigate = useNavigate();
    const _buttons: Array<ButtonProps> = buttons ||
        isResultStep ? [
            {onClick: () => navigate("/"), buttonStyle: EButtonStyle.SECONDARY, label: "На главную"} as ButtonProps,
        ] : [
            {onClick: openPreviousStep, buttonStyle: EButtonStyle.TERTIARY, buttonSize: EButtonSize.MEDIUM, label: "Назад"} as ButtonProps,
            {onClick: openNextStep, buttonStyle: EButtonStyle.PRIMARY, buttonSize: EButtonSize.MEDIUM, label: "Продолжить"} as ButtonProps,
        ];

    return (
        <>
            {body}
            <div className="margin-t-32 margin-all-r-32">
                {_buttons.map((buttonProps) => <Button key={generateUuid()} {...buttonProps} />)}
            </div>
        </>
    );
});

export const ErrorMasterStep = React.memo(function ErrorMasterStep({caption, body, buttons, error}: MasterStepProps & MasterAccumulatorInterface) {
    const _body: React.ReactNode = body || (
        <div className="flex-row">
            <IconErrorCircleColor className="margin-r-24 margin-t-8 icon-large-size" />
            <div>
                <H3 className="margin-no">{error?.errorTitle}</H3>
                <TextL className="margin-t-16">{error?.errorMessage}</TextL>
            </div>
        </div>
    );

    return (
        <MasterStep caption={caption} buttons={buttons} body={_body} isResultStep={true} />
    );
});