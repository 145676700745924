import React, {DetailsHTMLAttributes, useCallback, useContext} from "react";
import {Button, ButtonProps, EButtonStyle} from "../../components/controls/Button/Button";
import {Section} from "../Section/Section";
import {IconX} from "../../components/icons/NavigationIcons";
import {ModalContext} from "./context/ModalContext";
import {H3} from "../../components/text/Header/Header";
import {TextM} from "../../components/text/Text/Text";
import "./modal.scss";
import {generateUuid} from "../Utils";
import {IconErrorCircleColor, IconOkFullColor} from "../../components/icons/InfoIcons";
import Loader, {ELoaderSize, ELoaderType} from "../../components/info/Loader/Loader";

export type ModalWindowProps = {
    /** Рисуем крестик закрытия? По умолчанию считаем true */
    closable?: boolean;
    header?: React.ReactNode;
    body?: React.ReactNode;
    buttons?: Array<ButtonProps>;
    windowStyle?: ModalWindowStyle;
    /** Событие при закрытии модалки */
    onClose?: () => void;
} & DetailsHTMLAttributes<HTMLDivElement>;

export enum ModalWindowStyle {
    DEFAULT = "DEFAULT",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    LOADING = "LOADING",
}

const ModalWindowDefault = React.memo(function ModalWindow({closable, header, body, buttons, onClose, className, children, ...pp}: ModalWindowProps) {
    const {closeLastModalWindow} = useContext(ModalContext);
    const onCloseClick = useCallback(() => {
        closeLastModalWindow();
        !!onClose && onClose();
    }, [closeLastModalWindow, onClose]);
    const _buttons: Array<ButtonProps> = buttons || [{onClick: onCloseClick, buttonStyle: EButtonStyle.SECONDARY, label: "Закрыть"} as ButtonProps];

    return (
        <Section {...pp} className="modal-window padding-text">
            {closable !== false && (
                <div className="flex-col flex-align-end"><IconX onClick={onCloseClick} className="pointer"/></div>
            )}
            {!!header && (
                <H3 className="margin-t-8">{header}</H3>
            )}
            {!!body && (
                <TextM>{body}</TextM>
            )}
            <div className="flex-row flex-j-end margin-all-r-16">
                {_buttons.map((buttonProps) =>  <Button key={generateUuid()} {...buttonProps} />)}
            </div>
        </Section>
    );
});

const ModalSuccess = React.memo(function ModalSuccess({header, ...pp}: ModalWindowProps) {
    const iconHeader = (
        <div className="flex-row-center">
            <IconOkFullColor className="margin-r-24 icon-large-size" />
            {header}
        </div>
    );
    return (
        <ModalWindowDefault header={iconHeader} {...pp} />
    );
});

const ModalLoading = React.memo(function ModalLoading({header, ...pp}: ModalWindowProps) {
    const iconHeader = (
        <div className="flex-row-center">
            <Loader loaderSize={ELoaderSize.SMALL_SIZE} loaderType={ELoaderType.GREEN_TYPE} />
            {header}
        </div>
    );

    return (
        <ModalWindowDefault header={iconHeader} {...pp} />
    );
});

const ModalError = React.memo(function ModalError({header, ...pp}: ModalWindowProps) {
    const iconHeader = (
        <div className="flex-row-center">
            <IconErrorCircleColor className="margin-r-24 icon-large-size" />
            {header}
        </div>
    );
    return (
        <ModalWindowDefault header={iconHeader} {...pp} />
    );
});

export function ModalWindow({windowStyle, ...pp}: ModalWindowProps) {
    switch (windowStyle) {
        case ModalWindowStyle.LOADING:
            return <ModalLoading {...pp} />;
        case ModalWindowStyle.SUCCESS:
            return <ModalSuccess {...pp} />;
        case ModalWindowStyle.ERROR:
            return <ModalError {...pp} />;
        default:
            return <ModalWindowDefault {...pp} />;
    }
}