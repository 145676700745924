import {ETextFieldState, TextField, TextFieldProps} from "./TextField";
import React, {useEffect, useState} from "react";

type Props = {
    textFieldRegex: ETextFieldTypeRegex;
    onErrorValue?: () => void;
} & TextFieldProps;

export function ValidationTextField({value, textFieldRegex, onErrorValue, fieldState, fieldStateText, ...pp}: Props) {
    const [isValueValid, setIsValueValid] = useState<boolean>(true);

    useEffect(() => {
        setIsValueValid(checkTextFieldValue(textFieldRegex, value));
        onErrorValue && onErrorValue();
    }, [value]);

    return (
        <TextField
            value={value}
            fieldState={isValueValid ? fieldState : ETextFieldState.ERROR}
            fieldStateText={isValueValid ? fieldStateText : "Проверьте корректность введённых данных"}
            {...pp}
        />
    )
}

export enum ETextFieldTypeRegex {
    EMAIL = "^(?!.*\\.{2,})([\\w$-][\\w.$-]*[\\w$-]|[\\w$-])(\\+([\\w$-][\\w.$-]*[\\w$-]|[\\w$-]))?@[a-zA-Z\\d][a-zA-Z\\d\\.-]*\\.[a-zA-Z\\d]{2,12}$",
    PHONE = "^(79\\d{9})$",
    LOGIN = "^(?=([a-zA-Z]{1,}))[a-zA-Z0-9_\\.-]{0,30}$",
    PASSWORD = "^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){1,})[a-zA-Z0-9!@#$%*()_\\-:;.]{9,16}$",
    FIO = "^[\\-\\s\\t\\r\\n]*([а-яА-ЯёЁ-]+[\\s\\t\\r\\n]+){2,}[а-яА-ЯёЁ-]+[\\-\\s\\t\\r\\n]*$",
    NUMBER = "^-?[0-9]*$",
    NUMBER_FLOAT = "^-?([0-9]*[.])?[0-9]+$",
}

export function checkTextFieldValue(type: ETextFieldTypeRegex, value?: string): boolean {
    if (!type || !value) {
        return true;
    }
    return new RegExp(type).test(value);
}