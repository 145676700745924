// @ts-ignore
import MrYarosLogo from "./lib/core/TypicalHeader/MrYarosLogo.png";
import {Button, EButtonStyle, EButtonSize, EButtonWidth} from './lib/components/controls/Button/Button';
import { H1, H2, H3, H4 } from './lib/components/text/Header/Header';
import { TextL, TextM, TextS } from './lib/components/text/Text/Text';
import {IconArrowRightSmall, IconErrorSmall, IconLoaderSmall, IconOkCircleSmall, IconOkSmall, IconPlusSmall, IconWarningSmall, IconXSquareSmall} from "./lib/components/icons/SmallIcons";
import {IconArrowCornerDownLeft, IconArrowCornerDownRight, IconArrowCornerLeftDown, IconArrowCornerLeftUp, IconArrowCornerRightDown, IconArrowCornerRightUp, IconArrowCornerUpLeft, IconArrowCornerUpRight, IconArrowDown, IconArrowDownCircle, IconArrowDownLeft, IconArrowDownRight, IconArrowLeft, IconArrowLeftCircle, IconArrowRight, IconArrowRightCircle, IconArrowUp, IconArrowUpCircle, IconArrowUpLeft, IconArrowUpRight, IconChevronCode, IconChevronDown, IconChevronLeft, IconChevronRight, IconChevronUp, IconEdit, IconFile, IconFileText, IconFolder, IconHardDrive, IconHome, IconList, IconMaximize, IconMenu, IconMinimize, IconOk, IconOkCircle, IconOkSquare, IconSearch, IconSettings, IconShield, IconShieldOff, IconUser, IconUserCheck, IconUserMinus, IconUserPlus, IconUsers, IconUserX, IconWifi, IconX, IconXSquare} from "./lib/components/icons/NavigationIcons";
import {IconActivity, IconAlignCenter, IconAlignJustify, IconAlignLeft, IconAlignRight, IconArchive, IconArrowMaximize, IconArrowMinimize, IconBarChart, IconBarChart2, IconBell, IconBellOff, IconBold, IconBookmark, IconBookmarkColor, IconCast, IconColumns, IconCommand, IconCopy, IconCrop, IconCrosshair, IconDelete, IconDownloadCloud, IconEditSquare, IconEditUnderlining, IconExternalLink, IconEye, IconEyeOff, IconFileMinus, IconFilePlus, IconFilter, IconFilterColor, IconFolderMinus, IconFolderPlus, IconGarbage, IconGrid, IconHeart, IconHeartColor, IconImage, IconInbox, IconItalic, IconLayers, IconLayout, IconLink, IconLink2, IconLoader, IconLock, IconLogIn, IconLogOut, IconMail, IconMessage, IconMinus, IconMinusCircle, IconMinusColorCircle, IconMoreVertical, IconMove, IconPaperclip, IconPhone, IconPhoneIncoming, IconPhoneMissed, IconPhoneOff, IconPhoneOutgoing, IconPieChart, IconPlus, IconPlusCircle, IconPlusColorCircle, IconPocket, IconPrinter, IconRefreshCcw, IconRefreshCw, IconRepeat, IconRotateCcw, IconRotateCw, IconRss, IconSave, IconSend, IconServer, IconShare, IconShuffle, IconSidebar, IconStar, IconStarColor, IconStrech, IconThumbsDown, IconThumbsUp, IconTrash, IconTrendingDown, IconTrendingUp, IconType, IconUnderline, IconUnlock, IconUpload} from "./lib/components/icons/ActionIcons";
import {IconAlertOctagon, IconAlertOctagonColor, IconAlertOctagonMonochrome, IconErrorCircle, IconErrorCircleColor, IconErrorCircleMonochrome, IconErrorOctagon, IconErrorOctagonColor, IconErrorOctagonMonochrome, IconHelp, IconHelpColor, IconHelpMonochrome, IconInfo, IconInfoColor, IconInfoMonochrome, IconOkColorCircle, IconOkFullColor, IconOkMonochrome, IconSlash, IconWarning, IconWarningColor, IconWarningMonochrome} from "./lib/components/icons/InfoIcons";
import {IconFrown, IconMeh, IconSmile} from "./lib/components/icons/SmileIcons";
import {IconBox, IconBriefcase, IconCircle, IconClipboard, IconCreditCard, IconDatabase, IconDisc, IconDivide, IconDollarSign, IconGift, IconKey, IconPackage, IconPercent, IconTerminal} from "./lib/components/icons/SpecialIcons";
import {IconAirplay, IconAtSign, IconBluetooth, IconChrome, IconCodesandbox, IconCompass, IconDribbble, IconFacebook, IconGlobe, IconInstagram, IconLifeBuoy, IconLinkedin, IconMonitor, IconTv, IconTwitch, IconTwitter, IconYoutube} from "./lib/components/icons/SocialIcons";
import {TextField, ETextFieldState} from "./lib/components/controls/Field/TextField";
import {PasswordField} from "./lib/components/controls/Field/PasswordField";
import {NumberField} from "./lib/components/controls/Field/NumberField";
import {ValidationTextField, ETextFieldTypeRegex} from "./lib/components/controls/Field/ValidationTextField";
import {Notice, ENoticeType} from "./lib/components/info/Notice/Notice";
import {TypicalHeader} from "./lib/core/TypicalHeader/TypicalHeader";
import {TypicalFooter} from "./lib/core/TypicalFooter/TypicalFooter";
import {Background} from "./lib/core/Background/Background";
import {Checkbox} from "./lib/components/controls/Checkbox/Checkbox";
import {RadioButton} from "./lib/components/controls/RadioButton/RadioButton";
import {Switcher} from "./lib/components/controls/Switcher/Switcher";
import Loader, {ELoaderSize, ELoaderType} from "./lib/components/info/Loader/Loader";
import {TabControl} from "./lib/components/controls/Tab/TabControl";
// import {TabHeaderComponentProps} from "./lib/components/controls/Tab/TabHeaderItem";
import {Section} from "./lib/core/Section/Section";
import ModalProvider from "./lib/core/Modal/context/ModalContext";
import {ModalContext} from "./lib/core/Modal/context/ModalContext";
import {ModalWindowStyle} from "./lib/core/Modal/ModalWindow";
import {Tooltip} from "./lib/components/info/Tooltip/Tooltip";
import MasterProvider, {MasterContext} from "./lib/core/Master/context/MasterContext";
import {MasterStep, ErrorMasterStep} from "./lib/core/Master/MasterStep";
import {LineChart} from "./lib/components/info/Chart/LineChart";
// import {LineNumberChartData} from "./lib/components/info/Chart/context/@types/ChartData";
import Image from "./lib/components/info/Image/Image";

export {
    Button, EButtonStyle, EButtonSize, EButtonWidth,
    H1, H2, H3, H4,
    TextL, TextM, TextS,
    IconArrowRightSmall, IconErrorSmall, IconLoaderSmall, IconOkCircleSmall, IconOkSmall, IconPlusSmall, IconWarningSmall, IconXSquareSmall,
    IconArrowCornerDownLeft, IconArrowCornerDownRight, IconArrowCornerLeftDown, IconArrowCornerLeftUp, IconArrowCornerRightDown, IconArrowCornerRightUp, IconArrowCornerUpLeft, IconArrowCornerUpRight, IconArrowDown, IconArrowDownCircle, IconArrowDownLeft, IconArrowDownRight, IconArrowLeft, IconArrowLeftCircle, IconArrowRight, IconArrowRightCircle, IconArrowUp, IconArrowUpCircle, IconArrowUpLeft, IconArrowUpRight, IconChevronCode, IconChevronDown, IconChevronLeft, IconChevronRight, IconChevronUp, IconEdit, IconFile, IconFileText, IconFolder, IconHardDrive, IconHome, IconList, IconMaximize, IconMenu, IconMinimize, IconOk, IconOkCircle, IconOkSquare, IconSearch, IconSettings, IconShield, IconShieldOff, IconUser, IconUserCheck, IconUserMinus, IconUserPlus, IconUsers, IconUserX, IconWifi, IconX, IconXSquare,
    IconActivity, IconAlignCenter, IconAlignJustify, IconAlignLeft, IconAlignRight, IconArchive, IconArrowMaximize, IconArrowMinimize, IconBarChart, IconBarChart2, IconBell, IconBellOff, IconBold, IconBookmark, IconBookmarkColor, IconCast, IconColumns, IconCommand, IconCopy, IconCrop, IconCrosshair, IconDelete, IconDownloadCloud, IconEditSquare, IconEditUnderlining, IconExternalLink, IconEye, IconEyeOff, IconFileMinus, IconFilePlus, IconFilter, IconFilterColor, IconFolderMinus, IconFolderPlus, IconGarbage, IconGrid, IconHeart, IconHeartColor, IconImage, IconInbox, IconItalic, IconLayers, IconLayout, IconLink, IconLink2, IconLoader, IconLock, IconLogIn, IconLogOut, IconMail, IconMessage, IconMinus, IconMinusCircle, IconMinusColorCircle, IconMoreVertical, IconMove, IconPaperclip, IconPhone, IconPhoneIncoming, IconPhoneMissed, IconPhoneOff, IconPhoneOutgoing, IconPieChart, IconPlus, IconPlusCircle, IconPlusColorCircle, IconPocket, IconPrinter, IconRefreshCcw, IconRefreshCw, IconRepeat, IconRotateCcw, IconRotateCw, IconRss, IconSave, IconSend, IconServer, IconShare, IconShuffle, IconSidebar, IconStar, IconStarColor, IconStrech, IconThumbsDown, IconThumbsUp, IconTrash, IconTrendingDown, IconTrendingUp, IconType, IconUnderline, IconUnlock, IconUpload,
    IconAlertOctagon, IconAlertOctagonColor, IconAlertOctagonMonochrome, IconErrorCircle, IconErrorCircleColor, IconErrorCircleMonochrome, IconErrorOctagon, IconErrorOctagonColor, IconErrorOctagonMonochrome, IconHelp, IconHelpColor, IconHelpMonochrome, IconInfo, IconInfoColor, IconInfoMonochrome, IconOkColorCircle, IconOkFullColor, IconOkMonochrome, IconSlash, IconWarning, IconWarningColor, IconWarningMonochrome,
    IconFrown, IconMeh, IconSmile,
    IconBox, IconBriefcase, IconCircle, IconClipboard, IconCreditCard, IconDatabase, IconDisc, IconDivide, IconDollarSign, IconGift, IconKey, IconPackage, IconPercent, IconTerminal,
    IconAirplay, IconAtSign, IconBluetooth, IconChrome, IconCodesandbox, IconCompass, IconDribbble, IconFacebook, IconGlobe, IconInstagram, IconLifeBuoy, IconLinkedin, IconMonitor, IconTv, IconTwitch, IconTwitter, IconYoutube,
    ETextFieldState, TextField, PasswordField, NumberField, ETextFieldTypeRegex, ValidationTextField,
    Notice, ENoticeType,
    TypicalHeader, MrYarosLogo, TypicalFooter,
    Background,
    Checkbox, RadioButton, Switcher,
    Loader, ELoaderSize, ELoaderType,
    TabControl,
    // TabHeaderComponentProps,
    Section,
    ModalProvider, ModalContext, ModalWindowStyle,
    Tooltip,
    MasterProvider, MasterContext, MasterStep, ErrorMasterStep,
    LineChart,
    // LineNumberChartData,
    Image,
};

export default {
    Button, EButtonStyle, EButtonSize, EButtonWidth,
    H1, H2, H3, H4,
    TextL, TextM, TextS,
    IconArrowRightSmall, IconErrorSmall, IconLoaderSmall, IconOkCircleSmall, IconOkSmall, IconPlusSmall, IconWarningSmall, IconXSquareSmall,
    IconArrowCornerDownLeft, IconArrowCornerDownRight, IconArrowCornerLeftDown, IconArrowCornerLeftUp, IconArrowCornerRightDown, IconArrowCornerRightUp, IconArrowCornerUpLeft, IconArrowCornerUpRight, IconArrowDown, IconArrowDownCircle, IconArrowDownLeft, IconArrowDownRight, IconArrowLeft, IconArrowLeftCircle, IconArrowRight, IconArrowRightCircle, IconArrowUp, IconArrowUpCircle, IconArrowUpLeft, IconArrowUpRight, IconChevronCode, IconChevronDown, IconChevronLeft, IconChevronRight, IconChevronUp, IconEdit, IconFile, IconFileText, IconFolder, IconHardDrive, IconHome, IconList, IconMaximize, IconMenu, IconMinimize, IconOk, IconOkCircle, IconOkSquare, IconSearch, IconSettings, IconShield, IconShieldOff, IconUser, IconUserCheck, IconUserMinus, IconUserPlus, IconUsers, IconUserX, IconWifi, IconX, IconXSquare,
    IconActivity, IconAlignCenter, IconAlignJustify, IconAlignLeft, IconAlignRight, IconArchive, IconArrowMaximize, IconArrowMinimize, IconBarChart, IconBarChart2, IconBell, IconBellOff, IconBold, IconBookmark, IconBookmarkColor, IconCast, IconColumns, IconCommand, IconCopy, IconCrop, IconCrosshair, IconDelete, IconDownloadCloud, IconEditSquare, IconEditUnderlining, IconExternalLink, IconEye, IconEyeOff, IconFileMinus, IconFilePlus, IconFilter, IconFilterColor, IconFolderMinus, IconFolderPlus, IconGarbage, IconGrid, IconHeart, IconHeartColor, IconImage, IconInbox, IconItalic, IconLayers, IconLayout, IconLink, IconLink2, IconLoader, IconLock, IconLogIn, IconLogOut, IconMail, IconMessage, IconMinus, IconMinusCircle, IconMinusColorCircle, IconMoreVertical, IconMove, IconPaperclip, IconPhone, IconPhoneIncoming, IconPhoneMissed, IconPhoneOff, IconPhoneOutgoing, IconPieChart, IconPlus, IconPlusCircle, IconPlusColorCircle, IconPocket, IconPrinter, IconRefreshCcw, IconRefreshCw, IconRepeat, IconRotateCcw, IconRotateCw, IconRss, IconSave, IconSend, IconServer, IconShare, IconShuffle, IconSidebar, IconStar, IconStarColor, IconStrech, IconThumbsDown, IconThumbsUp, IconTrash, IconTrendingDown, IconTrendingUp, IconType, IconUnderline, IconUnlock, IconUpload,
    IconAlertOctagon, IconAlertOctagonColor, IconAlertOctagonMonochrome, IconErrorCircle, IconErrorCircleColor, IconErrorCircleMonochrome, IconErrorOctagon, IconErrorOctagonColor, IconErrorOctagonMonochrome, IconHelp, IconHelpColor, IconHelpMonochrome, IconInfo, IconInfoColor, IconInfoMonochrome, IconOkColorCircle, IconOkFullColor, IconOkMonochrome, IconSlash, IconWarning, IconWarningColor, IconWarningMonochrome,
    IconFrown, IconMeh, IconSmile,
    IconBox, IconBriefcase, IconCircle, IconClipboard, IconCreditCard, IconDatabase, IconDisc, IconDivide, IconDollarSign, IconGift, IconKey, IconPackage, IconPercent, IconTerminal,
    IconAirplay, IconAtSign, IconBluetooth, IconChrome, IconCodesandbox, IconCompass, IconDribbble, IconFacebook, IconGlobe, IconInstagram, IconLifeBuoy, IconLinkedin, IconMonitor, IconTv, IconTwitch, IconTwitter, IconYoutube,
    ETextFieldState, TextField, PasswordField, NumberField, ETextFieldTypeRegex, ValidationTextField,
    Notice, ENoticeType,
    TypicalHeader, MrYarosLogo, TypicalFooter,
    Background,
    Checkbox, RadioButton, Switcher,
    Loader, ELoaderSize, ELoaderType,
    TabControl,
    Section,
    ModalProvider, ModalContext, ModalWindowStyle,
    Tooltip,
    MasterProvider, MasterContext, MasterStep, ErrorMasterStep,
    LineChart,
    Image,
};

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);