import React from "react";
import {HTMLAttributes} from "react";
import "./header.scss"
import classNames from "classnames";

export function H1(props: HTMLAttributes<HTMLElement>) {
    const {children, className} = {...props};
    return (
        <h1 className={classNames(className, "header H1")}>{children}</h1>
    );
}

export function H2(props: HTMLAttributes<HTMLElement>) {
    const {children, className} = {...props};
    return (
        <h2 className={classNames(className, "header H2")}>{children}</h2>
    );
}

export function H3(props: HTMLAttributes<HTMLElement>) {
    const {children, className} = {...props};
    return (
        <h3 className={classNames(className, "header H3")}>{children}</h3>
    );
}

export function H4(props: HTMLAttributes<HTMLElement>) {
    const {children, className} = {...props};
    return (
        <h4 className={classNames(className, "header H4")}>{children}</h4>
    );
}