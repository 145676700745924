import React, {HTMLAttributes, useCallback, useRef, useState} from "react";
import {ErrorMasterStep, MasterStepProps} from "../MasterStep";
import {MasterAccumulatorInterface, MasterContextType} from "./@types/MasterContext";
import {MasterError} from "./@types/MasterError";
import {MasterHeader} from "../MasterHeader";
import {useNavigate} from "react-router-dom";

export type MasterChildrenArray = Array<React.ReactElement<MasterStepProps> | undefined>;

type MasterProps = {
    children: MasterChildrenArray;
} & HTMLAttributes<HTMLElement>;

const MasterContextDefault = {
    handleMasterError: (error: MasterError) => {},

    accumulator: {},

    openNextStep: () => {},
    openPreviousStep: () => {},
};

export const MasterContext = React.createContext<MasterContextType<any>>(MasterContextDefault);

function MasterProvider<A extends MasterAccumulatorInterface = {}>(props: MasterProps) {
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
    const accumulator: A = useRef({} as A).current;

    //состояние только для обновления компонента в случае возникновения ошибки на последнем шаге
    const [isError, setIsError] = useState<boolean>(false);

    const openNextStep = useCallback(() => {
        setActiveStepIndex((index) => {
            if (index == props.children.length - 1) {
                return index;
            }
            return index + 1;
        })
    }, []);

    const navigate = useNavigate();
    const openPreviousStep = useCallback(() => {
        if (activeStepIndex == 0) {
            navigate("/");
            return;
        }
        setActiveStepIndex((index) => {
            return index - 1;
        });
    }, [activeStepIndex]);

    const handleMasterError = useCallback((error: MasterError) => {
        accumulator.error = error;
        setActiveStepIndex(props.children.length - 1);
        setIsError(true);
    }, []);

    return (
        <MasterContext.Provider value={{openNextStep, openPreviousStep, accumulator, handleMasterError}}>
            <MasterHeader captions={props.children.map((child) => child?.props.caption)} activeStepIndex={activeStepIndex} isError={!!accumulator.error} />
            {accumulator.error ? <ErrorMasterStep caption="" error={accumulator.error} /> : props.children[activeStepIndex]}
        </MasterContext.Provider>
    )
}

export default MasterProvider;