import React from "react";
import "./background.scss";

export const Background = React.memo(function Background() {
    return(
        <div className="background">
            <div className="green-1"></div>
            <div className="green-2"></div>
            <div className="green-3"></div>
            <div className="green-4"></div>
            <div className="blue-1"></div>
            <div className="blue-2"></div>
            <div className="blue-3"></div>
            <div className="blue-4"></div>
        </div>
    );
});