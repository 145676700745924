import React, {useState} from "react";
import {H2, H3} from "../lib/components/text/Header/Header";
import {Checkbox} from "../lib/components/controls/Checkbox/Checkbox";
import {RadioButton} from "../lib/components/controls/RadioButton/RadioButton";
import {Switcher} from "../lib/components/controls/Switcher/Switcher";

export function SelectControlsExample() {
    const [checked, setChecked] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<number>(0);
    const [switcherChecked, setSwitcherChecked] = useState<boolean>(false);
    return (
        <>
            <H2>Select controls</H2>
            <H3>Checkbox</H3>
            <Checkbox checked={checked} onAction={setChecked} className="margin-b-24" label="label"/>
            <Checkbox checked={checked} onAction={setChecked} disabled />

            <H3>Radio Button</H3>
            <RadioButton selected={selectedItem == 0} onChange={() => setSelectedItem(0)} label="label" className="margin-b-24" />
            <RadioButton selected={selectedItem == 1} onChange={() => setSelectedItem(1)} label="label1" className="margin-b-24" />
            <RadioButton selected={selectedItem == 2} onChange={() => setSelectedItem(2)} label="label2" disabled className="margin-b-120" />

            <H3>Switcher</H3>
            <Switcher checked={switcherChecked} onAction={setSwitcherChecked} label="label" className="margin-b-24" />
            <Switcher checked={switcherChecked} onAction={setSwitcherChecked} label="label1" disabled className="margin-b-24" />
            <Switcher checked={switcherChecked} onAction={setSwitcherChecked} label="label2" className="margin-b-120" />
        </>
    )
}