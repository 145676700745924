import React, {useContext, useEffect} from "react";
import * as d3 from "d3";
import {ChartContext} from "../context/ChartContext";

export type MarginPropType = {
    top: number, right: number, bottom: number, left: number
};

export const Line = React.memo(function Line() {
    const {data, scaleFuncY, scaleFuncX, curve, svg} = useContext(ChartContext);

    useEffect(() => {
        const line = d3.line()
            .x(d => scaleFuncX(d[0]))
            .y(d => scaleFuncY(d[1]));

        if (curve) {
            line.curve(d3.curveCardinal);
        }

        svg.append && svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "#0E9CFF")
            .attr("stroke-width", 2)
            .attr("d", line);
    }, []);

    return (
        <>
        </>
    )
})