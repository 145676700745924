import React, {useContext, useEffect} from "react";
import {ChartContext} from "../context/ChartContext";
import * as d3 from "d3";

export const ChartGridX = React.memo(function ChartGridX() {
    const {scaleFuncX, height, svg} = useContext(ChartContext);
    useEffect(() => {
        const gridlines = d3.axisTop(scaleFuncX)
            .tickFormat(() => "")
            .tickSize(-height)
            .scale(scaleFuncX);

        svg.append && svg.append("g")
            .attr("class", "grid")
            .attr("color", "#CCCCCC")
            .attr("stroke-width", 0.5)
            .call(gridlines);
    }, []);

    return (
        <></>
    );
})

export const ChartGridY = React.memo(function ChartGridY() {
    const {scaleFuncY, width, svg} = useContext(ChartContext);

    useEffect(() => {
        const gridlinesY = d3.axisLeft(scaleFuncY)
            .tickFormat(() => "")
            .tickSize(-width)
            .scale(scaleFuncY);

        svg.append && svg.append("g")
            .attr("class", "grid")
            .attr("color", "#CCCCCC")
            .attr("stroke-width", 0.5)
            .call(gridlinesY);
    }, []);

    return (
        <></>
    );
})