import {makeSvgIconFromPath} from "./SvgIcons";
import React from "react";

export const IconErrorCircle = makeSvgIconFromPath(
    "error-circle",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 9L9 15" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 9L15 15" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconErrorCircleColor = makeSvgIconFromPath(
    "error-circle-color",
    <>
        <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#E60045" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 9L9 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 9L15 15" stroke="#FFFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconErrorCircleMonochrome = makeSvgIconFromPath(
    "error-circle-monochrome",
    <>
        <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#FFC4D6" stroke="#FFC5D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 9L9 15" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 9L15 15" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconErrorOctagon = makeSvgIconFromPath(
    "error-octagon",
    <>
        <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 9L9 15" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 9L15 15" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconErrorOctagonColor = makeSvgIconFromPath(
    "error-octagon-color",
    <>
        <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" fill="#E60045" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 9L9 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 9L15 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconErrorOctagonMonochrome = makeSvgIconFromPath(
    "error-octagon-monochrome",
    <>
        <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" fill="#FFC4D6" stroke="#FFC4D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 9L9 15" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 9L15 15" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconAlertOctagon = makeSvgIconFromPath(
    "alert-octagon",
    <>
        <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 8V12" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 16H12.01" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconAlertOctagonColor = makeSvgIconFromPath(
    "alert-octagon-color",
    <>
        <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" fill="#E60045" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 8V12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 16H12.01" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconAlertOctagonMonochrome = makeSvgIconFromPath(
    "alert-octagon-monochrome",
    <>
        <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" fill="#FFC4D6" stroke="#FFC4D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 8V12" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 16H12.01" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconInfo = makeSvgIconFromPath(
    "info",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 16V12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 8H12.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconInfoColor = makeSvgIconFromPath(
    "info-color",
    <>
        <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#0050E6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 16V12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 8H12.01" stroke="#FFFEFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconInfoMonochrome = makeSvgIconFromPath(
    "info-monochrome",
    <>
        <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#BFD5FF" stroke="#BFD5FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 8V12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 16H12.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconWarning = makeSvgIconFromPath(
    "warning",
    <>
        <path d="M10.2898 3.85996L1.81978 18C1.64514 18.3024 1.55274 18.6453 1.55177 18.9945C1.55079 19.3437 1.64127 19.6871 1.8142 19.9905C1.98714 20.2939 2.2365 20.5467 2.53748 20.7238C2.83847 20.9009 3.18058 20.9961 3.52978 21H20.4698C20.819 20.9961 21.1611 20.9009 21.4621 20.7238C21.7631 20.5467 22.0124 20.2939 22.1854 19.9905C22.3583 19.6871 22.4488 19.3437 22.4478 18.9945C22.4468 18.6453 22.3544 18.3024 22.1798 18L13.7098 3.85996C13.5315 3.56607 13.2805 3.32308 12.981 3.15444C12.6814 2.98581 12.3435 2.89722 11.9998 2.89722C11.656 2.89722 11.3181 2.98581 11.0186 3.15444C10.7191 3.32308 10.468 3.56607 10.2898 3.85996V3.85996Z" stroke="#FEC705" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 9V13" stroke="#FEC705" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 17H12.01" stroke="#FEC705" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconWarningColor = makeSvgIconFromPath(
    "warning-color",
    <>
        <path d="M10.2898 3.85996L1.81978 18C1.64514 18.3024 1.55274 18.6453 1.55177 18.9945C1.55079 19.3437 1.64127 19.6871 1.8142 19.9905C1.98714 20.2939 2.2365 20.5467 2.53748 20.7238C2.83847 20.9009 3.18058 20.9961 3.52978 21H20.4698C20.819 20.9961 21.1611 20.9009 21.4621 20.7238C21.7631 20.5467 22.0124 20.2939 22.1854 19.9905C22.3583 19.6871 22.4488 19.3437 22.4478 18.9945C22.4468 18.6453 22.3544 18.3024 22.1798 18L13.7098 3.85996C13.5315 3.56607 13.2805 3.32308 12.981 3.15444C12.6814 2.98581 12.3435 2.89722 11.9998 2.89722C11.656 2.89722 11.3181 2.98581 11.0186 3.15444C10.7191 3.32308 10.468 3.56607 10.2898 3.85996Z" fill="#FEC705" stroke="#FEC705" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 9V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 17H12.01" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconWarningMonochrome = makeSvgIconFromPath(
    "warning-monochrome",
    <>
        <path d="M10.2898 3.85996L1.81978 18C1.64514 18.3024 1.55274 18.6453 1.55177 18.9945C1.55079 19.3437 1.64127 19.6871 1.8142 19.9905C1.98714 20.2939 2.2365 20.5467 2.53748 20.7238C2.83847 20.9009 3.18058 20.9961 3.52978 21H20.4698C20.819 20.9961 21.1611 20.9009 21.4621 20.7238C21.7631 20.5467 22.0124 20.2939 22.1854 19.9905C22.3583 19.6871 22.4488 19.3437 22.4478 18.9945C22.4468 18.6453 22.3544 18.3024 22.1798 18L13.7098 3.85996C13.5315 3.56607 13.2805 3.32308 12.981 3.15444C12.6814 2.98581 12.3435 2.89722 11.9998 2.89722C11.656 2.89722 11.3181 2.98581 11.0186 3.15444C10.7191 3.32308 10.468 3.56607 10.2898 3.85996Z" fill="#FFEDAC" stroke="#FFEDAC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 9V13" stroke="#FEC705" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 17H12.01" stroke="#FEC705" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconHelp = makeSvgIconFromPath(
    "help",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#8F00E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.08984 9.00008C9.32495 8.33175 9.789 7.76819 10.3998 7.40921C11.0106 7.05024 11.7287 6.91902 12.427 7.03879C13.1253 7.15857 13.7587 7.52161 14.2149 8.06361C14.6712 8.60561 14.9209 9.2916 14.9198 10.0001C14.9198 12.0001 11.9198 13.0001 11.9198 13.0001" stroke="#8F00E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 17H12.01" stroke="#8F00E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconHelpColor = makeSvgIconFromPath(
    "help-color",
    <>
        <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#8F00E6" stroke="#8F00E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.08984 9.00008C9.32495 8.33175 9.789 7.76819 10.3998 7.40921C11.0106 7.05024 11.7287 6.91902 12.427 7.03879C13.1253 7.15857 13.7587 7.52161 14.2149 8.06361C14.6712 8.60561 14.9209 9.2916 14.9198 10.0001C14.9198 12.0001 11.9198 13.0001 11.9198 13.0001" stroke="#FFFDFD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 17H12.01" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconHelpMonochrome = makeSvgIconFromPath(
    "help-monochrome",
    <>
        <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#DFABFF" stroke="#DFABFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.08984 9.00008C9.32495 8.33175 9.789 7.76819 10.3998 7.40921C11.0106 7.05024 11.7287 6.91902 12.427 7.03879C13.1253 7.15857 13.7587 7.52161 14.2149 8.06361C14.6712 8.60561 14.9209 9.2916 14.9198 10.0001C14.9198 12.0001 11.9198 13.0001 11.9198 13.0001" stroke="#8F00E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 17H12.01" stroke="#8F00E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconOkColorCircle = makeSvgIconFromPath(
    "ok-color-circle",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.1998 9L15.4669 10L14.2002 11.7273L11.4998 15.2727L11.0331 14.7955L10.5665 14.3182L9.63314 13.3636L8.96647 12.6818L8.2998 12" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconOkFullColor = makeSvgIconFromPath(
    "ok-full-color",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#00E695" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.1998 9L15.4669 10L14.2002 11.7273L11.4998 15.2727L11.0331 14.7955L10.5665 14.3182L9.63314 13.3636L8.96647 12.6818L8.2998 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconOkMonochrome = makeSvgIconFromPath(
    "ok-monochrome",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#CDFFF1" stroke="#CDFFF1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.1998 9L15.4669 10L14.2002 11.7273L11.4998 15.2727L11.0331 14.7955L10.5665 14.3182L9.63314 13.3636L8.96647 12.6818L8.2998 12" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconSlash = makeSvgIconFromPath(
    "slash",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#757575" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.92969 4.92993L19.0697 19.0699" stroke="#757575" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);