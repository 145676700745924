import React, {useContext, useEffect} from "react";
import {ChartContext} from "../context/ChartContext";
import * as d3 from "d3";
import {LineNumberChartData} from "../context/@types/ChartData";
import "./chart-components.scss";

export const ChartDot = React.memo(function ChartDot() {
    const {data, scaleFuncX, scaleFuncY, svg} = useContext(ChartContext);

    useEffect(() => {
        // add dot
        svg.selectAll("myCircles")
            .data(data)
            .enter()
            .append("circle")
            .attr("class", "dot")
            .attr("cx", d => scaleFuncX(d[0]))
            .attr("cy", d => scaleFuncY(d[1]));
    }, []);

    const {height, width} = useContext(ChartContext);

    useEffect(() => {
        // Находит индекс элемента массива, который ближе всех к мышке
        const bisect = d3.bisector((d: LineNumberChartData) => d[0]).left;

        // Create the circle that travels along the curve of chart
        const focus = svg.append('g')
            .append('circle')
            .attr("class", "dot-focus")
            .style("opacity", 0);

        // Create the text that travels along the curve of chart
        const focusText = svg!!.append('g')
            .append('text')
            .style("opacity", 0)
            .attr("text-anchor", "left")
            .attr("alignment-baseline", "middle");

        // Create a rect on top of the svg area: this rectangle recovers mouse position
        svg.append('rect')
            .style("fill", "none")
            .style("pointer-events", "all")
            .attr('width', width)
            .attr('height', height)
            .on('mouseover', mouseover)
            .on('mousemove', mousemove)
            .on('mouseout', mouseout);


        // What happens when the mouse move -> show the annotations at the right positions.
        function mouseover() {
            focus.style("opacity", 1);
            focusText.style("opacity",1);
        }

        function mousemove(e: any) {
            // recover coordinate we need
            const x0 = scaleFuncX.invert(d3.pointer(e, svg.node())[0]);
            // Если оставить x0, то возвращает не ближайшую, а правую относительно мышки.
            // Поэтому надо делать - 0.5, но не может возвращать 0 - проблемы с первым элементом массива
            // Чтобы от этого избавиться, делаем + 0.5, т.е. по факту находим индекс следующего элемента, но потом берем i - 1
            // КОСТЫЛЬ!!!
            const i = bisect(data, x0 + 0.5, 1);
            const selectedData = data[i - 1];
            const rightMargin = i == data.length ? 50 : 30;
            focus
                .attr("cx", scaleFuncX(selectedData[0]))
                .attr("cy", scaleFuncY(selectedData[1]));
            focusText
                .html("x:" + selectedData[0] + ";   " + "y:" + selectedData[1])
                .attr("x", scaleFuncX(selectedData[0]) - rightMargin)
                .attr("y", scaleFuncY(selectedData[1]) + 20);
        }
        function mouseout() {
            focus.style("opacity", 0);
            focusText.style("opacity", 0);
        }
    }, []);

    return (
        <></>
    );
})