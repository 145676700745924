import React from "react";
import {Section} from "../Section/Section";
import {generateUuid} from "../Utils";
import "./master.scss";
import classNames from "classnames";

type MasterHeaderProps = {
    captions: Array<React.ReactNode>;
    activeStepIndex: number;
    isError?: boolean;
};
export const MasterHeader = React.memo(function MasterHeader({captions, activeStepIndex, isError}: MasterHeaderProps) {
    return (
        <Section className="master-header flex-row margin-b-48">
            {captions.map((caption, idx) =>
                <div
                    key={generateUuid()}
                    className={classNames("master-header_item", {
                        current: idx == activeStepIndex,
                        done: idx < activeStepIndex || (idx == activeStepIndex && idx == captions.length - 1 && !isError),
                        error: idx == activeStepIndex && idx == captions.length - 1 && isError
                    })}
                >
                    {caption}
                </div>
            )}
        </Section>
    );
});