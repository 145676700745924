import React from "react";
import {HTMLAttributes} from "react";
import classNames from "classnames";
import "./loader.scss";
import {H4} from "../../text/Header/Header";
import {TextS} from "../../text/Text/Text";

export enum ELoaderType {
    BLUE_TYPE = "blue_type",
    GREEN_TYPE = "green_type",
}

export enum ELoaderSize {
    BIG_SIZE = "big_size",
    SMALL_SIZE = "small_size",
    EXTRA_SMALL_SIZE = "extra_small_size",
}

type Props = {
    loaderType?: ELoaderType;
    loaderSize?: ELoaderSize;
    header?: string;
    description?: string;
} & HTMLAttributes<HTMLDivElement>;

function Loader({loaderType, loaderSize, header, description, className, children, ...pp}: Props) {
    return (
        <div className="flex-row-center">
            <div
                {...pp}
                className={classNames(
                    "loader margin-r-16",
                    className,
                    `loader-${(loaderType || ELoaderType.BLUE_TYPE)}`,
                    `loader-${(loaderSize || ELoaderSize.BIG_SIZE)}`
                )}
            >
                <svg width="0" height="0">
                    <defs>
                        <clipPath id="loader" clipPathUnits="objectBoundingBox">
                            <path d="M0.5,0.5m-0.5,0a0.5,0.5,0,0,1,1,0,0.5,0.5,0,0,1-1,0zM0.5,0.5m-0.375,0a0.375,0.375,0,0,0,0.75,0,0.375,0.375,0,0,0-0.75,0z"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div className="flex-col">
                {(!loaderSize || loaderSize == ELoaderSize.BIG_SIZE) && header ? (<H4 className="margin-t-8 margin-b-8">{header}</H4>) : null}
                {description ? (<TextS className="margin-no">{description}</TextS>) : null}
                {children ? (<div>{children}</div>) : null}
            </div>
        </div>
    )
}

export default React.memo(Loader);