import React, {useContext} from "react";
import {H2} from "../lib/components/text/Header/Header";
import {ModalContext} from "../lib/core/Modal/context/ModalContext";
import {Button, EButtonStyle} from "../lib/components/controls/Button/Button";
import {ModalWindowStyle} from "../lib/core/Modal/ModalWindow";

export function ModalExample() {
    const {pushModalWindow, closeLastModalWindow, closeAllModalWindows} = useContext(ModalContext);
    return (
        <>
            <H2>Modals</H2>
            <div className="flex-row flex-j-between margin-b-80">
                <Button
                    label="Открыть модалку"
                    onClick={()=> pushModalWindow({
                        header: "Модалка",
                        body: "Если разобрать макет или страницу сайта по элементам и посмотреть на вертикальные отступы между ними, вырисовываются закономерности. Разных по высоте вертикальных отступов не так уж и много на отдельно взятом сайте. Например, на главной Яндекса их всего 4. На странице продукта Apple — 8.",
                        onClose: () => console.log("Закрыли модалку"),
                        buttons: [
                            {label: "Открыть еще одну", onClick: () =>
                                    pushModalWindow({
                                        header: "Еще одна модалка",
                                        buttons: [
                                            {label: "Закрыть все модалки", onClick: closeAllModalWindows},
                                            {label: "Закрыть текущую модалку", onClick: closeLastModalWindow, buttonStyle: EButtonStyle.SECONDARY},
                                        ]})},
                            {label: "Закрыть", onClick: closeLastModalWindow, buttonStyle: EButtonStyle.SECONDARY},
                        ]
                    })}
                />
                <Button
                    label="Открыть модалку с ошибкой"
                    onClick={() => pushModalWindow({
                        header: "Произошла ошибка",
                        body: "Ну вот так",
                        windowStyle: ModalWindowStyle.ERROR,
                    })}
                />
                <Button
                    label="Открыть модалку с успехом"
                    onClick={() => pushModalWindow({
                        header: "Действие выполнено успешно",
                        windowStyle: ModalWindowStyle.SUCCESS,
                    })}
                />
                <Button
                    label="Открыть модалку с загрузкой"
                    onClick={() => pushModalWindow({
                        header: "Подождите, идет загрузка...",
                        windowStyle: ModalWindowStyle.LOADING,
                    })}
                />
            </div>
        </>
    );
}