import React, {useContext, useEffect, useState} from "react";
import {H2, H4} from "../lib/components/text/Header/Header";
import MasterProvider, {MasterContext} from "../lib/core/Master/context/MasterContext";
import {MasterStep} from "../lib/core/Master/MasterStep";
import {MasterAccumulatorInterface} from "../lib/core/Master/context/@types/MasterContext";
import {TextField} from "../lib/components/controls/Field/TextField";
import {TextS} from "../lib/components/text/Text/Text";
import {Button} from "../lib/components/controls/Button/Button";

type AccumulatorType = {
    value?: string;
} & MasterAccumulatorInterface;

export function MasterExample() {
    return (
        <>
            <H2>Master</H2>
            <MasterProvider<AccumulatorType>>
                <MasterStep caption="1 шаг" body={<ExampleMasterStep1_InitValue />}/>
                <MasterStep caption="2 шаг" body={<ExampleMasterStep2_ShowValue />}/>
                <MasterStep caption="3 шаг" body={<ExampleMasterStep3_Result />} isResultStep={true} />
            </MasterProvider>
        </>
    );
}

function ExampleMasterStep1_InitValue() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [inputValue, setInputValue] = useState<string | undefined>(_accumulator.value);

    useEffect(() => {
        _accumulator.value = inputValue;
    }, [inputValue]);

    return (
        <div>
            <H4>1 step</H4>
            <TextField value={inputValue} onChange={setInputValue} />
        </div>
    );
}

function ExampleMasterStep2_ShowValue() {
    const {accumulator, handleMasterError} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;

    return (
        <div>
            <H4>2 step</H4>
            <TextS>{_accumulator.value}</TextS>
            <Button label="Ошибка" onClick={() => handleMasterError({errorMessage: "Попробуйте позднее", errorTitle: "Произошла ошибка"})} />
        </div>
    );
}

function ExampleMasterStep3_Result() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;

    return (
        <div>
            <H4>3 step</H4>
            <TextS>Вот такое: {_accumulator.value}</TextS>
        </div>
    );
}