import React from "react";
import {H1, H2, H3, H4} from "../lib/components/text/Header/Header";
import {TextL, TextM} from "../lib/components/text/Text/Text";

export function TextExample() {
    return (
        <>
            <H1>H1.Вертикальные отступы </H1>
            <TextL>Если разобрать макет или страницу сайта по элементам и посмотреть на вертикальные отступы между ними, вырисовываются закономерности. Разных по высоте вертикальных отступов не так уж и много на отдельно взятом сайте. Например, на главной Яндекса их всего 4. На странице продукта Apple — 8.</TextL>
            <H2>H2.Как устроена система</H2>
            <TextL>Все элементы страницы группируются в блоки. Блоки выстраиваются по правилам, которые придумал дизайнер. Вертикальные отступы разделены по уровням. Первый уровень — самый невысокий отступ. Пример: Отступ вторго уровня(16px), ставлю между двумя кнопками или заголовком h3 и параграфом. Отступ шестого уровня(48px) отбивает два таких блока. И так далее.</TextL>
            <H3>H3.Закон Хика и геометрическая прогрессия</H3>
            <TextL>Чем больше количество вариантов, тем сложнее сделать выбор. – Закон Хика</TextL>
            <H4>H4.Закон Хика</H4>
            <TextM>Чем больше количество вариантов, тем сложнее сделать выбор.
                Чтобы создать устойчивую систему, которая упрощает выбор, нужно использовать минимально необходимое количество вариантов.
            </TextM>
        </>
    )
}