import React from "react";
import {makeSvgIconFromPath} from "./SvgIcons";

export const IconUpload = makeSvgIconFromPath(
    "upload",
    <>
        <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 8L12 3L7 8" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 3V15" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconExternalLink = makeSvgIconFromPath(
    "external-link",
    <>
        <path d="M18 13V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H11" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 3H21V9" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 14L21 3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconShare = makeSvgIconFromPath(
    "share",
    <>
        <path d="M18 2C17.2044 2 16.4413 2.31607 15.8787 2.87868C15.3161 3.44129 15 4.20435 15 5C15.0006 5.18815 15.0189 5.37583 15.0547 5.56055L7.93945 9.71094C7.39751 9.25184 6.71026 8.99992 6 9C5.20435 9 4.44129 9.31607 3.87868 9.87868C3.31607 10.4413 3 11.2044 3 12C3 12.7956 3.31607 13.5587 3.87868 14.1213C4.44129 14.6839 5.20435 15 6 15C6.70916 14.9986 7.3949 14.746 7.93555 14.2871L15.0547 18.4395C15.0189 18.6242 15.0006 18.8118 15 19C15 19.7956 15.3161 20.5587 15.8787 21.1213C16.4413 21.6839 17.2044 22 18 22C18.7956 22 19.5587 21.6839 20.1213 21.1213C20.6839 20.5587 21 19.7956 21 19C21 18.2044 20.6839 17.4413 20.1213 16.8787C19.5587 16.3161 18.7956 16 18 16C17.2902 16.0009 16.6037 16.2535 16.0625 16.7129L8.94531 12.5605C8.98108 12.3758 8.99939 12.1882 9 12C8.99939 11.8118 8.98108 11.6242 8.94531 11.4395L16.0605 7.28906C16.6025 7.74816 17.2897 8.00008 18 8C18.7956 8 19.5587 7.68393 20.1213 7.12132C20.6839 6.55871 21 5.79565 21 5C21 4.20435 20.6839 3.44129 20.1213 2.87868C19.5587 2.31607 18.7956 2 18 2Z" fill="#0152E5"/>
    </>
);

export const IconPlus = makeSvgIconFromPath(
    "plus",
    <>
        <path d="M12 5V19" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 12H19" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPlusCircle = makeSvgIconFromPath(
    "plus-circle",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 8V16" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 12H16" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPlusColorCircle = makeSvgIconFromPath(
    "plus-color-circle",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#00E695" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 8V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconMinus = makeSvgIconFromPath(
    "minus",
    <>
        <path d="M5 12H19" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconMinusCircle = makeSvgIconFromPath(
    "minus-circle",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 12H16" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconMinusColorCircle = makeSvgIconFromPath(
    "minus-color-circle",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#0050E6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconStar = makeSvgIconFromPath(
    "star",
    <>
        <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconStarColor = makeSvgIconFromPath(
    "star-color",
    <>
        <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" fill="#0050E6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconHeart = makeSvgIconFromPath(
    "heart",
    <>
        <path d="M20.8401 4.61012C20.3294 4.09912 19.7229 3.69376 19.0555 3.4172C18.388 3.14064 17.6726 2.99829 16.9501 2.99829C16.2276 2.99829 15.5122 3.14064 14.8448 3.4172C14.1773 3.69376 13.5709 4.09912 13.0601 4.61012L12.0001 5.67012L10.9401 4.61012C9.90843 3.57842 8.50915 2.99883 7.05012 2.99883C5.59109 2.99883 4.19181 3.57842 3.16012 4.61012C2.12843 5.64181 1.54883 7.04108 1.54883 8.50012C1.54883 9.95915 2.12843 11.3584 3.16012 12.3901L4.22012 13.4501L12.0001 21.2301L19.7801 13.4501L20.8401 12.3901C21.3511 11.8794 21.7565 11.2729 22.033 10.6055C22.3096 9.93801 22.4519 9.2226 22.4519 8.50012C22.4519 7.77763 22.3096 7.06222 22.033 6.39476C21.7565 5.7273 21.3511 5.12087 20.8401 4.61012V4.61012Z" stroke="#0152E5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconHeartColor = makeSvgIconFromPath(
    "heart-color",
    <>
        <path d="M20.8401 4.61012C20.3294 4.09912 19.7229 3.69376 19.0555 3.4172C18.388 3.14064 17.6726 2.99829 16.9501 2.99829C16.2276 2.99829 15.5122 3.14064 14.8448 3.4172C14.1773 3.69376 13.5709 4.09912 13.0601 4.61012L12.0001 5.67012L10.9401 4.61012C9.90843 3.57842 8.50915 2.99883 7.05012 2.99883C5.59109 2.99883 4.19181 3.57842 3.16012 4.61012C2.12843 5.64181 1.54883 7.04108 1.54883 8.50012C1.54883 9.95915 2.12843 11.3584 3.16012 12.3901L4.22012 13.4501L12.0001 21.2301L19.7801 13.4501L20.8401 12.3901C21.3511 11.8794 21.7565 11.2729 22.033 10.6055C22.3096 9.93801 22.4519 9.2226 22.4519 8.50012C22.4519 7.77763 22.3096 7.06222 22.033 6.39476C21.7565 5.7273 21.3511 5.12087 20.8401 4.61012Z" fill="#0050E6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconFilter = makeSvgIconFromPath(
    "filter",
    <>
        <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconFilterColor = makeSvgIconFromPath(
    "filter-color",
    <>
        <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" fill="#0050E6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconMessage = makeSvgIconFromPath(
    "message",
    <>
        <path d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z" stroke="url(#paint0_linear_125_589)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_125_589" x1="12" y1="3" x2="12" y2="21" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconPaperclip = makeSvgIconFromPath(
    "paperclip",
    <>
        <path d="M21.4403 11.0499L12.2503 20.2399C11.1244 21.3658 9.59747 21.9983 8.00529 21.9983C6.41311 21.9983 4.88613 21.3658 3.76029 20.2399C2.63445 19.1141 2.00195 17.5871 2.00195 15.9949C2.00195 14.4027 2.63445 12.8758 3.76029 11.7499L12.9503 2.55992C13.7009 1.80936 14.7188 1.3877 15.7803 1.3877C16.8417 1.3877 17.8597 1.80936 18.6103 2.55992C19.3609 3.31048 19.7825 4.32846 19.7825 5.38992C19.7825 6.45138 19.3609 7.46936 18.6103 8.21992L9.41029 17.4099C9.03501 17.7852 8.52602 17.996 7.99529 17.996C7.46456 17.996 6.95557 17.7852 6.58029 17.4099C6.20501 17.0346 5.99418 16.5256 5.99418 15.9949C5.99418 15.4642 6.20501 14.9552 6.58029 14.5799L15.0703 6.09992" stroke="url(#paint0_linear_125_590)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_125_590" x1="11.7211" y1="1.3877" x2="11.7211" y2="21.9983" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconGarbage = makeSvgIconFromPath(
    "garbage",
    <>
        <path d="M3 6H5H21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 11V17" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 11V17" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconTrash = makeSvgIconFromPath(
    "trash",
    <>
        <path d="M3 6H5H21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconDelete = makeSvgIconFromPath(
    "delete",
    <>
        <path d="M21 4H8L1 12L8 20H21C21.5304 20 22.0391 19.7893 22.4142 19.4142C22.7893 19.0391 23 18.5304 23 18V6C23 5.46957 22.7893 4.96086 22.4142 4.58579C22.0391 4.21071 21.5304 4 21 4V4Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 9L12 15" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 9L18 15" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconEditSquare = makeSvgIconFromPath(
    "edit-square",
    <>
        <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="url(#paint0_linear_123_521)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="url(#paint1_linear_123_521)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_123_521" x1="11" y1="4" x2="11" y2="22" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_123_521" x1="15.0607" y1="1.87866" x2="15.0607" y2="16" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconEditUnderlining = makeSvgIconFromPath(
    "edit-underlining",
    <>
        <path d="M12 20H21" stroke="url(#paint0_linear_123_523)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.5 3.49998C16.8978 3.10216 17.4374 2.87866 18 2.87866C18.2786 2.87866 18.5544 2.93353 18.8118 3.04014C19.0692 3.14674 19.303 3.303 19.5 3.49998C19.697 3.69697 19.8532 3.93082 19.9598 4.18819C20.0665 4.44556 20.1213 4.72141 20.1213 4.99998C20.1213 5.27856 20.0665 5.55441 19.9598 5.81178C19.8532 6.06915 19.697 6.303 19.5 6.49998L7 19L3 20L4 16L16.5 3.49998Z" stroke="url(#paint1_linear_123_523)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_123_523" x1="16.5" y1="20" x2="16.5" y2="21" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_123_523" x1="11.5607" y1="2.87866" x2="11.5607" y2="20" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconFilePlus = makeSvgIconFromPath(
    "file-plus",
    <>
        <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="url(#paint0_linear_123_518)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 2V8H20" stroke="url(#paint1_linear_123_518)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 18V12" stroke="url(#paint2_linear_123_518)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 15H15" stroke="url(#paint3_linear_123_518)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_123_518" x1="12" y1="2" x2="12" y2="22" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_123_518" x1="17.5" y1="4.5" x2="13" y2="9.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
            <linearGradient id="paint2_linear_123_518" x1="12.5" y1="12" x2="12.5" y2="18" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
            <linearGradient id="paint3_linear_123_518" x1="9" y1="12.5" x2="12" y2="18" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconFileMinus = makeSvgIconFromPath(
    "file-minus",
    <>
        <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="url(#paint0_linear_123_519)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 2V8H20" stroke="url(#paint1_linear_123_519)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 15H15" stroke="url(#paint2_linear_123_519)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_123_519" x1="12" y1="2" x2="12" y2="22" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_123_519" x1="18.5" y1="5" x2="14" y2="10" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
            <linearGradient id="paint2_linear_123_519" x1="9" y1="13.5" x2="14" y2="18" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconFolderMinus = makeSvgIconFromPath(
    "folder-minus",
    <>
        <path d="M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z" stroke="url(#paint0_linear_123_525)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 14H15" stroke="url(#paint1_linear_123_525)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_123_525" x1="12" y1="3" x2="12" y2="21" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_123_525" x1="7.5" y1="11.5" x2="14.5" y2="16.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconFolderPlus = makeSvgIconFromPath(
    "folder-plus",
    <>
        <path d="M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z" stroke="url(#paint0_linear_123_526)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 11V17" stroke="url(#paint1_linear_123_526)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 14H15" stroke="url(#paint2_linear_123_526)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_123_526" x1="12" y1="3" x2="12" y2="21" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_123_526" x1="12.5" y1="11" x2="12.5" y2="17" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
            <linearGradient id="paint2_linear_123_526" x1="9" y1="13" x2="13.5" y2="16.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0050E6"/>
                <stop offset="1" stopColor="#00CAE6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconMail = makeSvgIconFromPath(
    "mail",
    <>
        <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 6L12 13L2 6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconEye = makeSvgIconFromPath(
    "eye",
    <>
        <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconEyeOff = makeSvgIconFromPath(
    "eye-off",
    <>
        <path d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 1L23 23" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconDownloadCloud = makeSvgIconFromPath(
    "download-cloud",
    <>
        <g clipPath="url(#clip0_125_603)">
            <path d="M8 17L12 21L16 17" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 12V21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.8802 18.0899C21.7496 17.4786 22.4015 16.6061 22.7415 15.5991C23.0814 14.5921 23.0916 13.503 22.7706 12.4898C22.4496 11.4766 21.814 10.592 20.9562 9.96449C20.0985 9.33697 19.063 8.9991 18.0002 8.99993H16.7402C16.4394 7.82781 15.8767 6.73918 15.0943 5.81601C14.3119 4.89285 13.3303 4.15919 12.2234 3.67029C11.1164 3.18138 9.91302 2.94996 8.7037 2.99345C7.49439 3.03694 6.31069 3.3542 5.24173 3.92136C4.17277 4.48851 3.2464 5.29078 2.53236 6.26776C1.81833 7.24474 1.33523 8.37098 1.11944 9.56168C0.903647 10.7524 0.960787 11.9765 1.28656 13.142C1.61233 14.3074 2.19824 15.3837 3.00018 16.2899" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_125_603">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </>
);

export const IconLogIn = makeSvgIconFromPath(
    "log-in",
    <>
        <path d="M15 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 17L15 12L10 7" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 12H3" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconLogOut = makeSvgIconFromPath(
    "log-out",
    <>
        <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 17L21 12L16 7" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 12H9" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconRotateCcw = makeSvgIconFromPath(
    "rotate-ccw",
    <>
        <path d="M1 4V10H7" stroke="url(#paint0_linear_121_227)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.51 14.9999C4.15839 16.8403 5.38734 18.4201 7.01166 19.5013C8.63598 20.5825 10.5677 21.1065 12.5157 20.9944C14.4637 20.8823 16.3226 20.1401 17.8121 18.8797C19.3017 17.6193 20.3413 15.9089 20.7742 14.0063C21.2072 12.1037 21.0101 10.1119 20.2126 8.33105C19.4152 6.55019 18.0605 5.07674 16.3528 4.13271C14.6451 3.18868 12.6769 2.82521 10.7447 3.09707C8.81245 3.36892 7.02091 4.26137 5.64 5.63995L1 9.99995" stroke="url(#paint1_linear_121_227)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_121_227" x1="1" y1="9" x2="7.5" y2="4.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_121_227" x1="1" y1="10" x2="14.5" y2="12" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconRotateCw = makeSvgIconFromPath(
    "rotate-cw",
    <>
        <path d="M23 4V10H17" stroke="url(#paint0_linear_121_226)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.4899 15C19.8399 16.8399 18.6094 18.4187 16.984 19.4985C15.3586 20.5783 13.4263 21.1006 11.4783 20.9866C9.53026 20.8726 7.67203 20.1286 6.18363 18.8667C4.69524 17.6047 3.6573 15.8932 3.22625 13.9901C2.79519 12.0869 2.99436 10.0952 3.79374 8.31508C4.59313 6.53496 5.94942 5.06288 7.65823 4.12065C9.36705 3.17843 11.3358 2.81711 13.2678 3.09116C15.1999 3.3652 16.9905 4.25975 18.3699 5.64001L22.9999 10" stroke="url(#paint1_linear_121_226)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_121_226" x1="22" y1="9" x2="11" y2="3.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_121_226" x1="22.5" y1="9.5" x2="4.5" y2="15.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconRefreshCw = makeSvgIconFromPath(
    "refresh-cw",
    <>
        <path d="M23 4V10H17" stroke="url(#paint0_linear_121_220)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 20V14H7" stroke="url(#paint1_linear_121_220)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.51 9.00008C4.01717 7.56686 4.87913 6.28548 6.01547 5.27549C7.1518 4.26551 8.52547 3.55984 10.0083 3.22433C11.4911 2.88883 13.0348 2.93442 14.4952 3.35685C15.9556 3.77928 17.2853 4.56479 18.36 5.64008L23 10.0001M1 14.0001L5.64 18.3601C6.71475 19.4354 8.04437 20.2209 9.50481 20.6433C10.9652 21.0657 12.5089 21.1113 13.9917 20.7758C15.4745 20.4403 16.8482 19.7346 17.9845 18.7247C19.1209 17.7147 19.9828 16.4333 20.49 15.0001" stroke="url(#paint2_linear_121_220)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_121_220" x1="22" y1="9.5" x2="11.5" y2="2" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_121_220" x1="11.5" y1="20" x2="1.5" y2="14.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
            <linearGradient id="paint2_linear_121_220" x1="22" y1="13" x2="1.5" y2="11.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconRefreshCcw = makeSvgIconFromPath(
    "refresh-ccw",
    <>
        <path d="M1 4V10H7" stroke="url(#paint0_linear_121_221)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23 20V14H17" stroke="url(#paint1_linear_121_221)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.49 9.00008C19.9828 7.56686 19.1209 6.28548 17.9845 5.27549C16.8482 4.26551 15.4745 3.55984 13.9917 3.22433C12.5089 2.88883 10.9652 2.93442 9.50481 3.35685C8.04437 3.77928 6.71475 4.56479 5.64 5.64008L1 10.0001M23 14.0001L18.36 18.3601C17.2853 19.4354 15.9556 20.2209 14.4952 20.6433C13.0348 21.0657 11.4911 21.1113 10.0083 20.7758C8.52547 20.4403 7.1518 19.7346 6.01547 18.7247C4.87913 17.7147 4.01717 16.4333 3.51 15.0001" stroke="url(#paint2_linear_121_221)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_121_221" x1="1.5" y1="9.5" x2="10" y2="3.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_121_221" x1="17.5" y1="19" x2="22.5" y2="14.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
            <linearGradient id="paint2_linear_121_221" x1="1.5" y1="10" x2="23" y2="14.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#0050E6"/>
            </linearGradient>
        </defs>
    </>
);

export const IconArrowMinimize = makeSvgIconFromPath(
    "arrow-minimize",
    <>
        <path d="M4 14H10V20" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20 10H14V4" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 10L21 3" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 21L10 14" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconArrowMaximize = makeSvgIconFromPath(
    "arrow-maximize",
    <>
        <path d="M15 3H21V9" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 21H3V15" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 3L14 10" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 21L10 14" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconMove = makeSvgIconFromPath(
    "move",
    <>
        <path d="M5 9L2 12L5 15" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 5L12 2L15 5" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 19L12 22L9 19" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19 9L22 12L19 15" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 12H22" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 2V22" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconRepeat = makeSvgIconFromPath(
    "repeat",
    <>
        <path d="M17 1L21 5L17 9" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 11V9C3 7.93913 3.42143 6.92172 4.17157 6.17157C4.92172 5.42143 5.93913 5 7 5H21" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 23L3 19L7 15" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 13V15C21 16.0609 20.5786 17.0783 19.8284 17.8284C19.0783 18.5786 18.0609 19 17 19H3" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconTrendingUp = makeSvgIconFromPath(
    "trending-up",
    <>
        <path d="M23 6L13.5 15.5L8.5 10.5L1 18" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 6H23V12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconTrendingDown = makeSvgIconFromPath(
    "trending-down",
    <>
        <path d="M23 18L13.5 8.5L8.5 13.5L1 6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 18H23V12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconActivity = makeSvgIconFromPath(
    "activity",
    <>
        <path d="M22 12H18L15 21L9 3L6 12H2" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconBarChart = makeSvgIconFromPath(
    "bar-chart",
    <>
        <path d="M12 20V10" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 20V4" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 20V16" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconBarChart2 = makeSvgIconFromPath(
    "bar-chart-2",
    <>
        <path d="M18 20V10" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 20V4" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 20V14" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPieChart = makeSvgIconFromPath(
    "pie-chart",
    <>
        <path d="M21.2104 15.8901C20.5742 17.3946 19.5792 18.7203 18.3123 19.7514C17.0454 20.7825 15.5452 21.4875 13.9428 21.8049C12.3405 22.1222 10.6848 22.0422 9.12055 21.5719C7.55627 21.1015 6.13103 20.2551 4.96942 19.1067C3.80782 17.9583 2.94522 16.5428 2.45704 14.984C1.96886 13.4252 1.86996 11.7706 2.169 10.1647C2.46804 8.55886 3.1559 7.05071 4.17245 5.77211C5.189 4.49351 6.50329 3.4834 8.0004 2.83008" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V12H22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconAlignJustify = makeSvgIconFromPath(
    "align-justify",
    <>
        <path d="M21 10H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 6H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 14H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 18H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconAlignCenter = makeSvgIconFromPath(
    "align-center",
    <>
        <path d="M18 10H6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 6H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 14H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 18H6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconAlignLeft = makeSvgIconFromPath(
    "align-left",
    <>
        <path d="M17 10H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 6H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 14H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 18H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconAlignRight = makeSvgIconFromPath(
    "align-right",
    <>
        <path d="M21 10H7" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 6H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 14H3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 18H7" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconShuffle = makeSvgIconFromPath(
    "shuffle",
    <>
        <path d="M16 3H21V8" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4 20L21 3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 16V21H16" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 15L21 21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4 4L9 9" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconUnlock = makeSvgIconFromPath(
    "unlock",
    <>
        <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 10.9999V6.99991C6.99876 5.75996 7.45828 4.56378 8.28938 3.64358C9.12047 2.72338 10.2638 2.14481 11.4975 2.0202C12.7312 1.89558 13.9671 2.23381 14.9655 2.96922C15.9638 3.70463 16.6533 4.78476 16.9 5.99991" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconLock = makeSvgIconFromPath(
    "lock",
    <>
        <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPocket = makeSvgIconFromPath(
    "pocket",
    <>
        <path d="M4 3H20C20.5304 3 21.0391 3.21071 21.4142 3.58579C21.7893 3.96086 22 4.46957 22 5V11C22 13.6522 20.9464 16.1957 19.0711 18.0711C17.1957 19.9464 14.6522 21 12 21C10.6868 21 9.38642 20.7413 8.17317 20.2388C6.95991 19.7362 5.85752 18.9997 4.92893 18.0711C3.05357 16.1957 2 13.6522 2 11V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3V3Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 10L12 14L16 10" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconRss = makeSvgIconFromPath(
    "rss",
    <>
        <path d="M4 11C6.38695 11 8.67613 11.9482 10.364 13.636C12.0518 15.3239 13 17.6131 13 20" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4 4C8.24346 4 12.3131 5.68571 15.3137 8.68629C18.3143 11.6869 20 15.7565 20 20" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 20C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconSave = makeSvgIconFromPath(
    "save",
    <>
        <path d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 21V13H7V21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 3V8H15" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconSend = makeSvgIconFromPath(
    "send",
    <>
        <path d="M22 2L11 13" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconLink = makeSvgIconFromPath(
    "link",
    <>
        <path d="M10 13C10.4295 13.5741 10.9774 14.0491 11.6066 14.3929C12.2357 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9548 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.5521 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.0002 11C13.5707 10.4259 13.0228 9.9508 12.3936 9.60704C11.7645 9.26328 11.0687 9.05886 10.3535 9.00765C9.63841 8.95643 8.92061 9.05961 8.24885 9.3102C7.5771 9.56079 6.96709 9.95291 6.4602 10.46L3.4602 13.46C2.54941 14.403 2.04544 15.666 2.05683 16.977C2.06822 18.288 2.59407 19.542 3.52111 20.4691C4.44815 21.3961 5.70221 21.922 7.01319 21.9334C8.32418 21.9447 9.58719 21.4408 10.5302 20.53L12.2402 18.82" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconLink2 = makeSvgIconFromPath(
    "link-2",
    <>
        <path d="M15 7H18C18.6566 7 19.3068 7.12933 19.9134 7.3806C20.52 7.63188 21.0712 8.00017 21.5355 8.46447C21.9998 8.92876 22.3681 9.47996 22.6194 10.0866C22.8707 10.6932 23 11.3434 23 12C23 12.6566 22.8707 13.3068 22.6194 13.9134C22.3681 14.52 21.9998 15.0712 21.5355 15.5355C21.0712 15.9998 20.52 16.3681 19.9134 16.6194C19.3068 16.8707 18.6566 17 18 17H15M9 17H6C5.34339 17 4.69321 16.8707 4.08658 16.6194C3.47995 16.3681 2.92876 15.9998 2.46447 15.5355C1.52678 14.5979 1 13.3261 1 12C1 10.6739 1.52678 9.40215 2.46447 8.46447C3.40215 7.52678 4.67392 7 6 7H9" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 12H16" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconImage = makeSvgIconFromPath(
    "image",
    <>
        <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 15L16 10L5 21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconLayers = makeSvgIconFromPath(
    "layers",
    <>
        <path d="M12 2L2 7L12 12L22 7L12 2Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 17L12 22L22 17" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 12L12 17L22 12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconInbox = makeSvgIconFromPath(
    "inbox",
    <>
        <path d="M22 12H16L14 15H10L8 12H2" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.45 5.11L2 12V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V12L18.55 5.11C18.3844 4.77679 18.1292 4.49637 17.813 4.30028C17.4967 4.10419 17.1321 4.0002 16.76 4H7.24C6.86792 4.0002 6.50326 4.10419 6.18704 4.30028C5.87083 4.49637 5.61558 4.77679 5.45 5.11V5.11Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconThumbsDown = makeSvgIconFromPath(
    "thumbs-down",
    <>
        <path d="M17 2.00012H19.67C20.236 1.99011 20.7859 2.18825 21.2154 2.55694C21.645 2.92562 21.9242 3.43918 22 4.00012V11.0001C21.9242 11.5611 21.645 12.0746 21.2154 12.4433C20.7859 12.812 20.236 13.0101 19.67 13.0001H17M10 15.0001V19.0001C10 19.7958 10.3161 20.5588 10.8787 21.1214C11.4413 21.684 12.2044 22.0001 13 22.0001L17 13.0001V2.00012H5.72003C5.2377 1.99466 4.76965 2.16371 4.40212 2.47611C4.0346 2.78851 3.79235 3.22321 3.72003 3.70012L2.34003 12.7001C2.29652 12.9868 2.31586 13.2794 2.39669 13.5579C2.47753 13.8363 2.61793 14.0938 2.80817 14.3126C2.99842 14.5314 3.23395 14.7062 3.49846 14.8249C3.76297 14.9436 4.05012 15.0034 4.34003 15.0001H10Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconThumbsUp = makeSvgIconFromPath(
    "thumbs-up",
    <>
        <path d="M7 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V13C2 12.4696 2.21071 11.9609 2.58579 11.5858C2.96086 11.2107 3.46957 11 4 11H7M14 9V5C14 4.20435 13.6839 3.44129 13.1213 2.87868C12.5587 2.31607 11.7956 2 11 2L7 11V22H18.28C18.7623 22.0055 19.2304 21.8364 19.5979 21.524C19.9654 21.2116 20.2077 20.7769 20.28 20.3L21.66 11.3C21.7035 11.0134 21.6842 10.7207 21.6033 10.4423C21.5225 10.1638 21.3821 9.90629 21.1919 9.68751C21.0016 9.46873 20.7661 9.29393 20.5016 9.17522C20.2371 9.0565 19.9499 8.99672 19.66 9H14Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconType = makeSvgIconFromPath(
    "type",
    <>
        <path d="M4 7V4H20V7" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 20H15" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 4V20" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconUnderline = makeSvgIconFromPath(
    "underline",
    <>
        <path d="M6 3V10C6 11.5913 6.63214 13.1174 7.75736 14.2426C8.88258 15.3679 10.4087 16 12 16C13.5913 16 15.1174 15.3679 16.2426 14.2426C17.3679 13.1174 18 11.5913 18 10V3" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4 21H20" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconBold = makeSvgIconFromPath(
    "bold",
    <>
        <path d="M6 4H14C15.0609 4 16.0783 4.42143 16.8284 5.17157C17.5786 5.92172 18 6.93913 18 8C18 9.06087 17.5786 10.0783 16.8284 10.8284C16.0783 11.5786 15.0609 12 14 12H6V4Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 12H15C16.0609 12 17.0783 12.4214 17.8284 13.1716C18.5786 13.9217 19 14.9391 19 16C19 17.0609 18.5786 18.0783 17.8284 18.8284C17.0783 19.5786 16.0609 20 15 20H6V12Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconItalic = makeSvgIconFromPath(
    "italic",
    <>
        <path d="M19 4H10" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 20H5" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 4L9 20" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

//TODO надо ли ?
export const IconLoader = makeSvgIconFromPath(
    "loader",
    <>
        <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM3.20245 12C3.20245 16.8588 7.14125 20.7976 12 20.7976C16.8588 20.7976 20.7976 16.8588 20.7976 12C20.7976 7.14125 16.8588 3.20245 12 3.20245C7.14125 3.20245 3.20245 7.14125 3.20245 12Z" fill="url(#paint0_angular_145_563)"/>
        <defs>
            <radialGradient id="paint0_angular_145_563" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12 12) rotate(90) scale(12)">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#DFC59E" stopOpacity="0"/>
            </radialGradient>
        </defs>
    </>
);

export const IconBookmark = makeSvgIconFromPath(
    "bookmark",
    <>
        <path d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconBookmarkColor = makeSvgIconFromPath(
    "bookmark-color",
    <>
        <path d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z" fill="#0050E6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconStrech = makeSvgIconFromPath(
    "strech",
    <>
        <path d="M7.41421 20.1422L6 18.728L18.7279 6.00011L20.1421 7.41432L7.41421 20.1422Z" fill="#0050E6"/>
        <path d="M19.435 13.7783L13.7782 19.4351L12.364 18.0209L18.0208 12.3641L19.435 13.7783Z" fill="#0050E6"/>
    </>
);

export const IconCopy = makeSvgIconFromPath(
    "copy",
    <>
        <path d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconCrop = makeSvgIconFromPath(
    "crop",
    <>
        <path d="M6.13 1L6 16C6 16.5304 6.21071 17.0391 6.58579 17.4142C6.96086 17.7893 7.46957 18 8 18H23" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 6.13L16 6C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V23" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconCrosshair = makeSvgIconFromPath(
    "crosshair",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 12H18" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 12H2" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 6V2" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 22V18" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconCast = makeSvgIconFromPath(
    "cast",
    <>
        <path d="M2 16.1C2.96089 16.296 3.84294 16.7702 4.53638 17.4636C5.22982 18.1571 5.70403 19.0391 5.9 20M2 12.05C4.03079 12.2759 5.92428 13.186 7.36911 14.6309C8.81395 16.0757 9.72414 17.9692 9.95 20M2 8V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H20C20.5304 4 21.0391 4.21071 21.4142 4.58579C21.7893 4.96086 22 5.46957 22 6V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20H14" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 20H2.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconArchive = makeSvgIconFromPath(
    "archive",
    <>
        <path d="M21 8V21H3V8" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23 3H1V8H23V3Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 12H14" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconMoreVertical = makeSvgIconFromPath(
    "more-vertical",
    <>
        <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconGrid = makeSvgIconFromPath(
    "grid",
    <>
        <path d="M10 3H3V10H10V3Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 3H14V10H21V3Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 14H14V21H21V14Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 14H3V21H10V14Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconColumns = makeSvgIconFromPath(
    "columns",
    <>
        <path d="M12 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H12M12 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H12M12 3V21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPrinter = makeSvgIconFromPath(
    "printer",
    <>
        <path d="M6 9V2H18V9" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V11C2 10.4696 2.21071 9.96086 2.58579 9.58579C2.96086 9.21071 3.46957 9 4 9H20C20.5304 9 21.0391 9.21071 21.4142 9.58579C21.7893 9.96086 22 10.4696 22 11V16C22 16.5304 21.7893 17.0391 21.4142 17.4142C21.0391 17.7893 20.5304 18 20 18H18" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 14H6V22H18V14Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconCommand = makeSvgIconFromPath(
    "command",
    <>
        <path d="M18 3C17.2044 3 16.4413 3.31607 15.8787 3.87868C15.3161 4.44129 15 5.20435 15 6V18C15 18.7956 15.3161 19.5587 15.8787 20.1213C16.4413 20.6839 17.2044 21 18 21C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 17.2044 20.6839 16.4413 20.1213 15.8787C19.5587 15.3161 18.7956 15 18 15H6C5.20435 15 4.44129 15.3161 3.87868 15.8787C3.31607 16.4413 3 17.2044 3 18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21C6.79565 21 7.55871 20.6839 8.12132 20.1213C8.68393 19.5587 9 18.7956 9 18V6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6C3 6.79565 3.31607 7.55871 3.87868 8.12132C4.44129 8.68393 5.20435 9 6 9H18C18.7956 9 19.5587 8.68393 20.1213 8.12132C20.6839 7.55871 21 6.79565 21 6C21 5.20435 20.6839 4.44129 20.1213 3.87868C19.5587 3.31607 18.7956 3 18 3Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconSidebar = makeSvgIconFromPath(
    "sidebar",
    <>
        <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 3V21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconServer = makeSvgIconFromPath(
    "server",
    <>
        <path d="M20 2H4C2.89543 2 2 2.89543 2 4V8C2 9.10457 2.89543 10 4 10H20C21.1046 10 22 9.10457 22 8V4C22 2.89543 21.1046 2 20 2Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20 14H4C2.89543 14 2 14.8954 2 16V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V16C22 14.8954 21.1046 14 20 14Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 6H6.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 18H6.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconLayout = makeSvgIconFromPath(
    "layout",
    <>
        <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 9H21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 21V9" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconBell = makeSvgIconFromPath(
    "bell",
    <>
        <path d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.7295 21C13.5537 21.3031 13.3014 21.5547 12.9978 21.7295C12.6941 21.9044 12.3499 21.9965 11.9995 21.9965C11.6492 21.9965 11.3049 21.9044 11.0013 21.7295C10.6977 21.5547 10.4453 21.3031 10.2695 21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconBellOff = makeSvgIconFromPath(
    "bell-off",
    <>
        <path d="M13.7295 21C13.5537 21.3031 13.3014 21.5547 12.9978 21.7295C12.6941 21.9044 12.3499 21.9965 11.9995 21.9965C11.6492 21.9965 11.3049 21.9044 11.0013 21.7295C10.6977 21.5547 10.4453 21.3031 10.2695 21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.6303 13C18.1855 11.3714 17.9734 9.68804 18.0003 8" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.26 6.26001C6.08627 6.82362 5.99861 7.41023 6 8.00001C6 15 3 17 3 17H17" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.9999 8.00011C18.0015 6.91318 17.7079 5.84622 17.1503 4.91321C16.5927 3.98019 15.7921 3.21617 14.834 2.70275C13.876 2.18934 12.7965 1.94581 11.7108 1.99818C10.6251 2.05056 9.57409 2.39686 8.66992 3.00011" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 1L23 23" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPhone = makeSvgIconFromPath(
    "phone",
    <>
        <path d="M22.0004 16.9201V19.9201C22.0016 20.1986 21.9445 20.4743 21.8329 20.7294C21.7214 20.9846 21.5577 21.2137 21.3525 21.402C21.1473 21.5902 20.905 21.7336 20.6412 21.8228C20.3773 21.912 20.0978 21.9452 19.8204 21.9201C16.7433 21.5857 13.7874 20.5342 11.1904 18.8501C8.77425 17.3148 6.72576 15.2663 5.19042 12.8501C3.5004 10.2413 2.44866 7.27109 2.12042 4.1801C2.09543 3.90356 2.1283 3.62486 2.21692 3.36172C2.30555 3.09859 2.44799 2.85679 2.63519 2.65172C2.82238 2.44665 3.05023 2.28281 3.30421 2.17062C3.5582 2.05843 3.83276 2.00036 4.11042 2.0001H7.11042C7.59573 1.99532 8.06621 2.16718 8.43418 2.48363C8.80215 2.80008 9.0425 3.23954 9.11042 3.7201C9.23704 4.68016 9.47187 5.62282 9.81042 6.5301C9.94497 6.88802 9.97408 7.27701 9.89433 7.65098C9.81457 8.02494 9.62928 8.36821 9.36042 8.6401L8.09042 9.9101C9.51398 12.4136 11.5869 14.4865 14.0904 15.9101L15.3604 14.6401C15.6323 14.3712 15.9756 14.1859 16.3495 14.1062C16.7235 14.0264 17.1125 14.0556 17.4704 14.1901C18.3777 14.5286 19.3204 14.7635 20.2804 14.8901C20.7662 14.9586 21.2098 15.2033 21.527 15.5776C21.8441 15.9519 22.0126 16.4297 22.0004 16.9201Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPhoneOff = makeSvgIconFromPath(
    "phone-off",
    <>
        <path d="M10.6804 13.3101C11.6953 14.3258 12.8422 15.2004 14.0904 15.9101L15.3604 14.6401C15.6323 14.3712 15.9756 14.1859 16.3495 14.1062C16.7235 14.0264 17.1125 14.0556 17.4704 14.1901C18.3777 14.5286 19.3204 14.7635 20.2804 14.8901C20.761 14.958 21.2004 15.1984 21.5169 15.5663C21.8333 15.9343 22.0052 16.4048 22.0004 16.8901V19.8901C22.0016 20.1686 21.9445 20.4443 21.8329 20.6994C21.7214 20.9546 21.5577 21.1837 21.3525 21.372C21.1473 21.5602 20.905 21.7036 20.6412 21.7928C20.3773 21.882 20.0978 21.9152 19.8204 21.8901C16.7433 21.5557 13.7874 20.5042 11.1904 18.8201C9.98569 18.0552 8.86895 17.1597 7.86042 16.1501M5.19042 12.8101C3.50628 10.2131 2.45478 7.25726 2.12042 4.1801C2.09543 3.90356 2.1283 3.62486 2.21692 3.36172C2.30555 3.09859 2.44799 2.85679 2.63519 2.65172C2.82238 2.44665 3.05023 2.28281 3.30421 2.17062C3.5582 2.05843 3.83276 2.00036 4.11042 2.0001H7.11042C7.59573 1.99532 8.06621 2.16718 8.43418 2.48363C8.80215 2.80008 9.0425 3.23954 9.11042 3.7201C9.23704 4.68016 9.47187 5.62282 9.81042 6.5301C9.94497 6.88802 9.97408 7.27701 9.89433 7.65098C9.81457 8.02494 9.62928 8.36821 9.36042 8.6401L8.09042 9.9101" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23 1L1 23" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPhoneOutgoing = makeSvgIconFromPath(
    "phone-outgoing",
    <>
        <path d="M23 7V1H17" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 8L23 1" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.0004 16.9201V19.9201C22.0016 20.1986 21.9445 20.4743 21.8329 20.7294C21.7214 20.9846 21.5577 21.2137 21.3525 21.402C21.1473 21.5902 20.905 21.7336 20.6412 21.8228C20.3773 21.912 20.0978 21.9452 19.8204 21.9201C16.7433 21.5857 13.7874 20.5342 11.1904 18.8501C8.77425 17.3148 6.72576 15.2663 5.19042 12.8501C3.5004 10.2413 2.44866 7.27109 2.12042 4.1801C2.09543 3.90356 2.1283 3.62486 2.21692 3.36172C2.30555 3.09859 2.44799 2.85679 2.63519 2.65172C2.82238 2.44665 3.05023 2.28281 3.30421 2.17062C3.5582 2.05843 3.83276 2.00036 4.11042 2.0001H7.11042C7.59573 1.99532 8.06621 2.16718 8.43418 2.48363C8.80215 2.80008 9.0425 3.23954 9.11042 3.7201C9.23704 4.68016 9.47187 5.62282 9.81042 6.5301C9.94497 6.88802 9.97408 7.27701 9.89433 7.65098C9.81457 8.02494 9.62928 8.36821 9.36042 8.6401L8.09042 9.9101C9.51398 12.4136 11.5869 14.4865 14.0904 15.9101L15.3604 14.6401C15.6323 14.3712 15.9756 14.1859 16.3495 14.1062C16.7235 14.0264 17.1125 14.0556 17.4704 14.1901C18.3777 14.5286 19.3204 14.7635 20.2804 14.8901C20.7662 14.9586 21.2098 15.2033 21.527 15.5776C21.8441 15.9519 22.0126 16.4297 22.0004 16.9201Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPhoneMissed = makeSvgIconFromPath(
    "phone-missed",
    <>
        <path d="M23 1L17 7" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 1L23 7" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.0004 16.9201V19.9201C22.0016 20.1986 21.9445 20.4743 21.8329 20.7294C21.7214 20.9846 21.5577 21.2137 21.3525 21.402C21.1473 21.5902 20.905 21.7336 20.6412 21.8228C20.3773 21.912 20.0978 21.9452 19.8204 21.9201C16.7433 21.5857 13.7874 20.5342 11.1904 18.8501C8.77425 17.3148 6.72576 15.2663 5.19042 12.8501C3.5004 10.2413 2.44866 7.27109 2.12042 4.1801C2.09543 3.90356 2.1283 3.62486 2.21692 3.36172C2.30555 3.09859 2.44799 2.85679 2.63519 2.65172C2.82238 2.44665 3.05023 2.28281 3.30421 2.17062C3.5582 2.05843 3.83276 2.00036 4.11042 2.0001H7.11042C7.59573 1.99532 8.06621 2.16718 8.43418 2.48363C8.80215 2.80008 9.0425 3.23954 9.11042 3.7201C9.23704 4.68016 9.47187 5.62282 9.81042 6.5301C9.94497 6.88802 9.97408 7.27701 9.89433 7.65098C9.81457 8.02494 9.62928 8.36821 9.36042 8.6401L8.09042 9.9101C9.51398 12.4136 11.5869 14.4865 14.0904 15.9101L15.3604 14.6401C15.6323 14.3712 15.9756 14.1859 16.3495 14.1062C16.7235 14.0264 17.1125 14.0556 17.4704 14.1901C18.3777 14.5286 19.3204 14.7635 20.2804 14.8901C20.7662 14.9586 21.2098 15.2033 21.527 15.5776C21.8441 15.9519 22.0126 16.4297 22.0004 16.9201Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPhoneIncoming = makeSvgIconFromPath(
    "phone-incoming",
    <>
        <path d="M16 2V8H22" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23 1L16 8" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.0004 16.9201V19.9201C22.0016 20.1986 21.9445 20.4743 21.8329 20.7294C21.7214 20.9846 21.5577 21.2137 21.3525 21.402C21.1473 21.5902 20.905 21.7336 20.6412 21.8228C20.3773 21.912 20.0978 21.9452 19.8204 21.9201C16.7433 21.5857 13.7874 20.5342 11.1904 18.8501C8.77425 17.3148 6.72576 15.2663 5.19042 12.8501C3.5004 10.2413 2.44866 7.27109 2.12042 4.1801C2.09543 3.90356 2.1283 3.62486 2.21692 3.36172C2.30555 3.09859 2.44799 2.85679 2.63519 2.65172C2.82238 2.44665 3.05023 2.28281 3.30421 2.17062C3.5582 2.05843 3.83276 2.00036 4.11042 2.0001H7.11042C7.59573 1.99532 8.06621 2.16718 8.43418 2.48363C8.80215 2.80008 9.0425 3.23954 9.11042 3.7201C9.23704 4.68016 9.47187 5.62282 9.81042 6.5301C9.94497 6.88802 9.97408 7.27701 9.89433 7.65098C9.81457 8.02494 9.62928 8.36821 9.36042 8.6401L8.09042 9.9101C9.51398 12.4136 11.5869 14.4865 14.0904 15.9101L15.3604 14.6401C15.6323 14.3712 15.9756 14.1859 16.3495 14.1062C16.7235 14.0264 17.1125 14.0556 17.4704 14.1901C18.3777 14.5286 19.3204 14.7635 20.2804 14.8901C20.7662 14.9586 21.2098 15.2033 21.527 15.5776C21.8441 15.9519 22.0126 16.4297 22.0004 16.9201Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);