import React from "react";
import {H2} from "../lib/components/text/Header/Header";
import {Button, EButtonSize, EButtonStyle, EButtonWidth} from "../lib/components/controls/Button/Button";
import {IconArrowRight} from "../lib/components/icons/NavigationIcons";
import {IconPlus} from "../lib/components/icons/ActionIcons";

export function ButtonExample() {
    return(
        <>
            <H2>Buttons</H2>
            <div className="margin-all-b-24">
                <div className="flex-row flex-align-center flex-j-around">
                    <Button buttonSize={EButtonSize.LARGE} disabled={true}>
                        <div className="margin-all-r-8 flex-row flex-align-center">
                            <div>Кнопка</div>
                            <IconArrowRight/>
                        </div>
                    </Button>
                    <Button buttonSize={EButtonSize.MEDIUM}>
                        <div className="margin-all-r-8 flex-row flex-align-center">
                            <IconPlus/>
                            <div>Кнопка</div>
                        </div>
                    </Button>
                    <Button buttonSize={EButtonSize.NORMAL} loading/>
                    <Button buttonSize={EButtonSize.SMALL}/>
                    <Button buttonSize={EButtonSize.EXTRA_SMALL}/>
                    <Button buttonSize={EButtonSize.LARGE}/>
                </div>
                <div className="flex-row flex-align-center flex-j-around">
                    <Button buttonSize={EButtonSize.LARGE} disabled={true} buttonStyle={EButtonStyle.SECONDARY}>
                        <div className="margin-all-r-8 flex-row flex-align-center">
                            <div>Кнопка</div>
                            <IconArrowRight/>
                        </div>
                    </Button>
                    <Button buttonSize={EButtonSize.MEDIUM} buttonStyle={EButtonStyle.SECONDARY}>
                        <div className="margin-all-r-8 flex-row flex-align-center">
                            <IconPlus/>
                            <div>Кнопка</div>
                        </div>
                    </Button>
                    <Button buttonSize={EButtonSize.NORMAL} buttonStyle={EButtonStyle.SECONDARY} loading/>
                    <Button buttonSize={EButtonSize.SMALL} buttonStyle={EButtonStyle.SECONDARY}/>
                    <Button buttonSize={EButtonSize.EXTRA_SMALL} buttonStyle={EButtonStyle.SECONDARY}/>
                    <Button buttonSize={EButtonSize.LARGE} buttonStyle={EButtonStyle.SECONDARY}/>
                </div>
                <div className="flex-row flex-align-center flex-j-around">
                    <Button buttonSize={EButtonSize.LARGE} disabled={true} buttonStyle={EButtonStyle.TERTIARY}>
                        <div className="margin-all-r-8 flex-row flex-align-center">
                            <div>Кнопка</div>
                            <IconArrowRight/>
                        </div>
                    </Button>
                    <Button buttonSize={EButtonSize.MEDIUM} buttonStyle={EButtonStyle.TERTIARY}>
                        <div className="margin-all-r-8 flex-row flex-align-center">
                            <IconPlus/>
                            <div>Кнопка</div>
                        </div>
                    </Button>
                    <Button buttonSize={EButtonSize.NORMAL} buttonStyle={EButtonStyle.TERTIARY} loading/>
                    <Button buttonSize={EButtonSize.SMALL} buttonStyle={EButtonStyle.TERTIARY}/>
                    <Button buttonSize={EButtonSize.EXTRA_SMALL} buttonStyle={EButtonStyle.TERTIARY}/>
                    <Button buttonSize={EButtonSize.LARGE} buttonStyle={EButtonStyle.TERTIARY}/>
                </div>
                <Button buttonWidth={EButtonWidth.FULL_WIDTH}/>
            </div>
        </>
    )
}