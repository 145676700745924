import React, {DetailsHTMLAttributes, ReactNode} from "react";
import "./typical-header.scss";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";

type Props = {
    logoSrc: string;
    headerLinks: ReactNode;
    additionalBlock?: ReactNode;
} & DetailsHTMLAttributes<HTMLDivElement>;

export const TypicalHeader = React.memo(function TypicalHeader({logoSrc, headerLinks, additionalBlock, className, ...pp}: Props) {
    const navigate = useNavigate();

    return (
        <div className={classNames("typical-header flex-row-center flex-j-center", className)} {...pp}>
            <div className="content flex-row-center flex-j-between">
                <img src={logoSrc} alt="logo" className="logo pointer" onClick={() => navigate("/")} />

                <div className="header-links margin-all-r-40">
                    {headerLinks}
                </div>

                <div>
                    {additionalBlock}
                </div>
            </div>
        </div>
    );
});