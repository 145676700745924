import React, {HTMLAttributes} from "react";
import {TabHeaderComponentProps} from "./TabHeaderItem";
import TabHeader from "./TabHeader";

type Props = {
    children: Array<React.ComponentElement<TabHeaderComponentProps, any>>;
    selectedTabId: string;
    onTabChange: (tabId: string) => void;
    disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export function TabControl({children, selectedTabId, onTabChange, disabled, className}: Props) {
    const activeChildren = children.find((child) => child.props.tabId == selectedTabId);

    return (
        <div className={className}>
            <TabHeader activeTabId={selectedTabId} onTabChange={onTabChange} disabled={disabled} tabComponentsList={children} />

            {activeChildren}
        </div>
    );
}