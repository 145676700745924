import React from "react";
import {H2} from "../lib/components/text/Header/Header";
import {Section} from "../lib/core/Section/Section";
import {LineChart} from "../lib/components/info/Chart/LineChart";
import {LineNumberChartData} from "../lib/components/info/Chart/context/@types/ChartData";

export function ChartExample() {
    const data = [
        [ 1, 12 ],
        [ 2, 5 ] ,
        [ 3, 6 ] ,
        [ 4, 6 ] ,
        [ 5, 9 ] ,
        [ 6, 10 ] ,
    ] as Array<LineNumberChartData>;
    return (
        <>
            <H2>Charts</H2>
            <Section className="padding-medium">
                <LineChart header = "Headline" description="Description" data={data} curve />
            </Section>
        </>
    );
}