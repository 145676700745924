import React from "react";
import {TabHeaderComponentProps, TabHeaderItem} from "./TabHeaderItem";
import {generateUuid} from "../../../core/Utils";

type Props = {
    onTabChange: (tabId: string) => void;
    activeTabId: string;
    disabled?: boolean;
    tabComponentsList: Array<React.ComponentElement<TabHeaderComponentProps, any>>;
};

function TabHeader({onTabChange, activeTabId, disabled, tabComponentsList}: Props) {
    return (
        <div className="flex-row-center flex-wrap">
            {tabComponentsList.map((component) =>
                <TabHeaderItem
                    key={generateUuid()}
                    activeTabId={activeTabId}
                    tabComponent={component}
                    onTabClick={onTabChange}
                    disabled={disabled}
                />
            )}
        </div>
    );
}

export default React.memo(TabHeader);