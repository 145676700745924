import React from "react";
import {HTMLAttributes} from "react";
import "./text.scss"
import classNames from "classnames";

export function TextL(props: HTMLAttributes<HTMLElement>) {
    const {children, className} = {...props};
    return (
        <div className={classNames(className, "text text-l")}>{children}</div>
    );
}

export function TextM(props: HTMLAttributes<HTMLElement>) {
    const {children, className} = {...props};
    return (
        <div className={classNames(className, "text text-m")}>{children}</div>
    );
}

export function TextS(props: HTMLAttributes<HTMLElement>) {
    const {children, className} = {...props};
    return (
        <div className={classNames(className, "text text-s")}>{children}</div>
    );
}

export function TextXS(props: HTMLAttributes<HTMLElement>) {
    const {children, className} = {...props};
    return (
        <div className={classNames(className, "text text-xs")}>{children}</div>
    );
}