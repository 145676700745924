import React, {DetailsHTMLAttributes, ReactNode, useCallback} from "react";
import {TextM} from "../../text/Text/Text";
import classNames from "classnames";
import "./switcher.scss";

type Props = {
    label?: ReactNode;
    checked: boolean;
    onAction: (checked: boolean) => void;
    disabled?: boolean;
} & DetailsHTMLAttributes<HTMLDivElement>;

export function Switcher({label, checked, onAction, disabled, className, children, ...pp}: Props) {
    const onClick = useCallback(() => {
        if (!disabled) {
            onAction(!checked);
        }
    }, [disabled, onAction, checked]);

    return (
        <div className={classNames("flex-row pointer width-fit-content", className)} onClick={onClick} {...pp}>
            <div className={classNames("switcher margin-r-8", {"default": !disabled && !checked, disabled, checked})}>
                <div className="slider"/>
            </div>
            {label || children ? (
                <TextM className="margin-no">{label || children}</TextM>
            ) : null}
        </div>
    )
}