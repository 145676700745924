import React, {HTMLAttributes, useLayoutEffect, useState} from "react";
import {ChartContextType} from "./@types/ChartContext";
import {MarginPropType} from "../components/Line";
import * as d3 from "d3";
import {ScaleLinear, Selection} from "d3";
import {generateUuid} from "../../../../core/Utils";
import {LineNumberChartData} from "./@types/ChartData";
import "./chart.scss";
import classNames from "classnames";

type ChartContextProps = {
    header?: string;
    description?: string;
    data: Array<LineNumberChartData>;
    scaleFuncX: ScaleLinear<number, number>;
    scaleFuncY: ScaleLinear<number, number>;
    curve?: boolean;
    width?: number;
    height?: number;
    margin?: MarginPropType;
} & HTMLAttributes<HTMLDivElement>;

const ChartContextDefault = {
    data: [],
    scaleFuncX: d3.scaleLinear(),
    scaleFuncY: d3.scaleLinear(),
    curve: false,
    width: 460,
    height: 400,
    margin: {top: 10, right: 10, bottom: 23, left: 32},
    svg: d3.select("#chart_empty").append("svg").append("g"),
};

export const ChartContext = React.createContext<ChartContextType>(ChartContextDefault);

function ChartProvider({header, description, data, scaleFuncX, scaleFuncY, curve, width, height, margin, className, children, ...pp}: ChartContextProps) {
    const _width = width || ChartContextDefault.width;
    const _height = height || ChartContextDefault.height;
    const _margin = margin || ChartContextDefault.margin;

    const drawWidth = _width - _margin.left - _margin.right;
    const drawHeight = _height - _margin.top - _margin.bottom;

    const _x = scaleFuncX && scaleFuncX.range([ 0, drawWidth ]);
    const _y = scaleFuncY && scaleFuncY.range([ drawHeight, 0 ]);

    const chartId = generateUuid();

    useLayoutEffect(() => {
        header && d3.select(`#chart_${chartId}`)
            .append("div")
            .attr("class", classNames("header margin-l-32", {"margin-b-16": !description}))
            .text(header);

        description && d3.select(`#chart_${chartId}`)
            .append("div")
            .attr("class", "description margin-b-16 margin-l-32")
            .text(description);
    }, []);

    const [svg, setSvg] = useState<Selection<SVGGElement, unknown, HTMLElement, any>>();
    useLayoutEffect(() => {
        const svg = d3.select(`#chart_${chartId}`)
            .append("svg")
            .attr("width", _width)
            .attr("height", _height)
            .append("g")
            .attr("transform",
                "translate(" + _margin.left + "," + _margin.top + ")");

        setSvg(svg);
    }, []);

    const ChartContextValue = {data: data, scaleFuncX: _x, scaleFuncY: _y, curve: !!curve, width: drawWidth, height: drawHeight, margin: _margin, svg: svg || ChartContextDefault.svg};
    return (
        <ChartContext.Provider value={ChartContextValue}>
            <div id={`chart_${chartId}`} {...pp} />
            {svg && children}
        </ChartContext.Provider>
    )
}

export default ChartProvider;