import React from "react";
import {H2} from "../lib/components/text/Header/Header";
import Image from "../lib/components/info/Image/Image";
// @ts-ignore
import ExampleImage from "./ExampleImage.png";

export function ImageExample() {
    return (
        <>
            <H2>Image</H2>
            <Image src={ExampleImage} alt="ExampleImage" name="Рисунок 1. Пример отображения графика" />
        </>
    );
}