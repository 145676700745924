import React from "react";
import {DetailsHTMLAttributes, ReactNode, useCallback} from "react";
import "./radio-button.scss";
import classNames from "classnames";
import {TextM} from "../../text/Text/Text";

type Props = {
    label?: ReactNode;
    selected: boolean;
    onChange: () => void;
    disabled?: boolean;
} & DetailsHTMLAttributes<HTMLDivElement>;

export function RadioButton({label, selected, onChange, disabled, className, children, ...pp}: Props) {
    const onClick = useCallback(() => {
        if (!disabled) {
            onChange();
        }
    }, [disabled, onChange]);

    return (
        <div className={classNames("flex-row pointer width-fit-content", className)} onClick={onClick} {...pp}>
            <div className={classNames("radio-button flex-row-center flex-j-center margin-r-8", {default: !disabled, disabled})}>
                {selected && <div className="radio-button-selected" />}
            </div>

            {label || children ? (
                <TextM className="margin-no">{label || children}</TextM>
            ) : null}
        </div>
    );
}