import React, {useState} from "react";
import {H2, H4} from "../lib/components/text/Header/Header";
import {TabControl} from "../lib/components/controls/Tab/TabControl";
import {TabHeaderComponentProps} from "../lib/components/controls/Tab/TabHeaderItem";
import {Section} from "../lib/core/Section/Section";

enum TabId {
    FIRST = "FIRST",
    SECOND = "SECOND",
    THIRD = "THIRD",
}
export function TabExample() {
    const [activeTab, setActiveTab] = useState(TabId.FIRST);
    return (
        <Section className="padding-text">
            <H2>Tab</H2>
            <TabControl selectedTabId={activeTab} onTabChange={(tab) => setActiveTab(tab as TabId)} className="margin-b-48">
                <FirstTab tabId={TabId.FIRST} tabName="Первый" />
                <SecondTab tabId={TabId.SECOND} tabName="Второй" />
                <ThirdTab tabId={TabId.THIRD} tabName="Третий" />
            </TabControl>
        </Section>
    );
}

function FirstTab({}: TabHeaderComponentProps) {
    return (
        <H4>
            Тело первого таба
        </H4>
    );
}

function SecondTab({}: TabHeaderComponentProps) {
    return (
        <H4>
            Тело второго таба
        </H4>
    );
}

function ThirdTab({}: TabHeaderComponentProps) {
    return (
        <H4>
            Тело третьего таба
        </H4>
    );
}