import React from "react";
import {H2, H3, H4} from "../lib/components/text/Header/Header";
import {
    IconArrowRightSmall, IconErrorSmall,
    IconLoaderSmall, IconOkCircleSmall, IconOkSmall,
    IconPlusSmall, IconWarningSmall,
    IconXSquareSmall
} from "../lib/components/icons/SmallIcons";
import {
    IconArrowCornerDownLeft,
    IconArrowCornerDownRight,
    IconArrowCornerLeftDown,
    IconArrowCornerLeftUp,
    IconArrowCornerRightDown,
    IconArrowCornerRightUp,
    IconArrowCornerUpLeft,
    IconArrowCornerUpRight,
    IconArrowDown,
    IconArrowDownCircle,
    IconArrowDownLeft,
    IconArrowDownRight,
    IconArrowLeft,
    IconArrowLeftCircle,
    IconArrowRight,
    IconArrowRightCircle,
    IconArrowUp,
    IconArrowUpCircle,
    IconArrowUpLeft,
    IconArrowUpRight,
    IconChevronCode,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronUp,
    IconEdit,
    IconFile,
    IconFileText,
    IconFolder, IconHardDrive, IconHome,
    IconList,
    IconMaximize,
    IconMenu,
    IconMinimize,
    IconOk,
    IconOkCircle,
    IconOkSquare,
    IconSearch, IconSettings, IconShield, IconShieldOff,
    IconUser,
    IconUserCheck, IconUserMinus, IconUserPlus,
    IconUsers, IconUserX, IconWifi,
    IconX,
    IconXSquare
} from "../lib/components/icons/NavigationIcons";
import {
    IconActivity,
    IconAlignCenter,
    IconAlignJustify,
    IconAlignLeft,
    IconAlignRight,
    IconArchive,
    IconArrowMaximize,
    IconArrowMinimize,
    IconBarChart,
    IconBarChart2, IconBell, IconBellOff,
    IconBold,
    IconBookmark,
    IconBookmarkColor,
    IconCast,
    IconColumns,
    IconCommand,
    IconCopy,
    IconCrop,
    IconCrosshair,
    IconDelete,
    IconDownloadCloud,
    IconEditSquare,
    IconEditUnderlining,
    IconExternalLink,
    IconEye,
    IconEyeOff,
    IconFileMinus,
    IconFilePlus,
    IconFilter,
    IconFilterColor,
    IconFolderMinus,
    IconFolderPlus,
    IconGarbage,
    IconGrid,
    IconHeart,
    IconHeartColor,
    IconImage,
    IconInbox,
    IconItalic,
    IconLayers, IconLayout,
    IconLink,
    IconLink2,
    IconLoader,
    IconLock,
    IconLogIn,
    IconLogOut,
    IconMail,
    IconMessage,
    IconMinus,
    IconMinusCircle,
    IconMinusColorCircle,
    IconMoreVertical,
    IconMove,
    IconPaperclip, IconPhone, IconPhoneIncoming, IconPhoneMissed, IconPhoneOff, IconPhoneOutgoing,
    IconPieChart,
    IconPlus,
    IconPlusCircle,
    IconPlusColorCircle,
    IconPocket,
    IconPrinter,
    IconRefreshCcw,
    IconRefreshCw,
    IconRepeat,
    IconRotateCcw,
    IconRotateCw,
    IconRss,
    IconSave,
    IconSend, IconServer,
    IconShare,
    IconShuffle, IconSidebar,
    IconStar,
    IconStarColor,
    IconStrech,
    IconThumbsDown,
    IconThumbsUp,
    IconTrash,
    IconTrendingDown,
    IconTrendingUp,
    IconType,
    IconUnderline,
    IconUnlock,
    IconUpload
} from "../lib/components/icons/ActionIcons";
import {
    IconAlertOctagon,
    IconAlertOctagonColor,
    IconAlertOctagonMonochrome,
    IconErrorCircle,
    IconErrorCircleColor,
    IconErrorCircleMonochrome,
    IconErrorOctagon,
    IconErrorOctagonColor,
    IconErrorOctagonMonochrome, IconHelp, IconHelpColor, IconHelpMonochrome,
    IconInfo,
    IconInfoColor,
    IconInfoMonochrome, IconOkColorCircle, IconOkFullColor, IconOkMonochrome, IconSlash,
    IconWarning, IconWarningColor, IconWarningMonochrome
} from "../lib/components/icons/InfoIcons";
import {IconFrown, IconMeh, IconSmile} from "../lib/components/icons/SmileIcons";
import {
    IconBox,
    IconBriefcase,
    IconCircle,
    IconClipboard,
    IconCreditCard,
    IconDatabase,
    IconDisc,
    IconDivide,
    IconDollarSign, IconGift, IconKey, IconPackage,
    IconPercent, IconTerminal
} from "../lib/components/icons/SpecialIcons";
import {
    IconAirplay,
    IconAtSign, IconBluetooth,
    IconChrome,
    IconCodesandbox, IconCompass,
    IconDribbble,
    IconFacebook,
    IconGlobe, IconInstagram, IconLifeBuoy, IconLinkedin, IconMonitor, IconTv, IconTwitch, IconTwitter, IconYoutube
} from "../lib/components/icons/SocialIcons";

export function IconExample() {
    return(
        <>
            <H2>Icons</H2>
            <div>
                <H3>SIZE 16</H3>
                <IconXSquareSmall/>
                <IconArrowRightSmall/>
                <IconPlusSmall/>
                <IconLoaderSmall/>
                <IconOkCircleSmall/>
                <IconWarningSmall/>
                <IconErrorSmall/>
                <IconOkSmall/>
            </div>

            <div>
                <H3>SIZE 24</H3>
                <H4>NAVIGATION</H4>
                <IconArrowLeft/>
                <IconArrowDownLeft/>
                <IconArrowDown/>
                <IconArrowDownRight/>
                <IconArrowUpLeft/>
                <IconArrowUp/>
                <IconArrowUpRight/>
                <IconArrowRight/>
                <IconArrowCornerUpLeft/>
                <IconArrowCornerDownLeft/>
                <IconArrowCornerUpRight/>
                <IconArrowCornerDownRight/>
                <IconArrowCornerRightUp/>
                <IconArrowCornerRightDown/>
                <IconArrowCornerLeftUp/>
                <IconArrowCornerLeftDown/>
                <IconChevronDown/>
                <IconChevronUp/>
                <IconChevronRight/>
                <IconChevronLeft/>
                <IconChevronCode/>
                <IconMenu/>
                <IconList/>
                <IconMinimize/>
                <IconMaximize/>

                <br/>

                <IconArrowLeftCircle/>
                <IconArrowRightCircle/>
                <IconArrowDownCircle/>
                <IconArrowUpCircle/>
                <IconSearch/>
                <IconFile/>
                <IconFolder/>
                <IconFileText/>
                <IconEdit/>
                <IconOkSquare/>
                <IconOkCircle/>
                <IconOk/>
                <IconXSquare/>
                <IconX/>

                <br/>
                <IconUser/>
                <IconUsers/>
                <IconUserCheck/>
                <IconUserX/>
                <IconUserMinus/>
                <IconUserPlus/>
                <IconSettings/>
                <IconHome/>
                <IconShield/>
                <IconShieldOff/>
                <IconHardDrive/>
                <IconWifi/>

                <H4>ACTIONS</H4>

                <IconUpload/>
                <IconExternalLink/>
                <IconShare/>
                <IconPlus/>
                <IconPlusCircle/>
                <IconPlusColorCircle/>
                <IconMinus/>
                <IconMinusCircle/>
                <IconMinusColorCircle/>
                <IconStar/>
                <IconStarColor/>
                <IconHeart/>
                <IconHeartColor/>
                <IconFilter/>
                <IconFilterColor/>
                <IconMessage/>
                <IconPaperclip/>
                <IconGarbage/>
                <IconTrash/>
                <IconDelete/>
                <IconEditSquare/>
                <IconEditUnderlining/>
                <IconFilePlus/>
                <IconFileMinus/>
                <IconFolderMinus/>
                <IconFolderPlus/>
                <IconMail/>
                <IconEye/>
                <IconEyeOff/>
                <IconDownloadCloud/>

                <br/>

                <IconLogIn/>
                <IconLogOut/>
                <IconRotateCcw/>
                <IconRotateCw/>
                <IconRefreshCw/>
                <IconRefreshCcw/>
                <IconArrowMinimize/>
                <IconArrowMaximize/>
                <IconMove/>
                <IconRepeat/>
                <IconTrendingUp/>
                <IconTrendingDown/>
                <IconActivity/>
                <IconBarChart/>
                <IconBarChart2/>
                <IconPieChart/>
                <IconAlignJustify/>
                <IconAlignCenter/>
                <IconAlignLeft/>
                <IconAlignRight/>
                <IconShuffle/>
                <IconUnlock/>
                <IconLock/>
                <IconPocket/>
                <IconRss/>
                <IconSave/>
                <IconSend/>
                <IconLink/>
                <IconLink2/>

                <br/>

                <IconImage/>
                <IconLayers/>
                <IconInbox/>
                <IconThumbsDown/>
                <IconThumbsUp/>
                <IconType/>
                <IconUnderline/>
                <IconBold/>
                <IconItalic/>
                <IconLoader/>
                <IconBookmark/>
                <IconBookmarkColor/>
                <IconStrech/>
                <IconCopy/>
                <IconCrop/>
                <IconCrosshair/>
                <IconCast/>
                <IconArchive/>
                <IconMoreVertical/>
                <IconGrid/>
                <IconColumns/>
                <IconPrinter/>
                <IconCommand/>
                <IconSidebar/>
                <IconServer/>
                <IconLayout/>
                <IconBell/>
                <IconBellOff/>

                <br/>

                <IconPhone/>
                <IconPhoneOff/>
                <IconPhoneOutgoing/>
                <IconPhoneMissed/>
                <IconPhoneIncoming/>

                <H4>INFO</H4>

                <IconErrorCircle/>
                <IconErrorCircleColor/>
                <IconErrorCircleMonochrome/>
                <IconErrorOctagon/>
                <IconErrorOctagonColor/>
                <IconErrorOctagonMonochrome/>
                <IconAlertOctagon/>
                <IconAlertOctagonColor/>
                <IconAlertOctagonMonochrome/>

                <br/>

                <IconInfo/>
                <IconInfoColor/>
                <IconInfoMonochrome/>

                <br/>

                <IconWarning/>
                <IconWarningColor/>
                <IconWarningMonochrome/>

                <br/>

                <IconHelp/>
                <IconHelpColor/>
                <IconHelpMonochrome/>

                <br/>

                <IconOkColorCircle/>
                <IconOkFullColor/>
                <IconOkMonochrome/>

                <br/>

                <IconSlash/>

                <H4>SMILE</H4>

                <IconFrown/>
                <IconSmile/>
                <IconMeh/>

                <H4>SPECIAL</H4>

                <IconPercent/>
                <IconDatabase/>
                <IconDisc/>
                <IconCreditCard/>
                <IconDivide/>
                <IconDollarSign/>
                <IconClipboard/>
                <IconCircle/>
                <IconTerminal/>
                <IconKey/>
                <IconGift/>
                <IconPackage/>
                <IconBriefcase/>
                <IconBox/>

                <H4>SOCIAL</H4>

                <IconAtSign/>
                <IconCodesandbox/>
                <IconChrome/>
                <IconGlobe/>
                <IconFacebook/>
                <IconDribbble/>
                <IconTwitch/>
                <IconTwitter/>
                <IconTv/>
                <IconYoutube/>
                <IconMonitor/>
                <IconCompass/>
                <IconInstagram/>
                <IconLifeBuoy/>
                <IconLinkedin/>
                <IconBluetooth/>
                <IconAirplay/>
            </div>
        </>
    );
}