import React from "react";
import {H2} from "../lib/components/text/Header/Header";
import {ENoticeType, Notice} from "../lib/components/info/Notice/Notice";

export function NoticeExample() {
    return (
        <>
            <H2>Notifications</H2>
            <Notice
                noticeType={ENoticeType.INFO}
                title="Info notification"
                text="Notification message. Here will be information."
                className="margin-b-48"
            />
            <Notice
                noticeType={ENoticeType.WARNING}
                title="Warning notification"
                text="Notification message. Here will be information."
                className="margin-b-48"
            />
            <Notice
                noticeType={ENoticeType.ERROR}
                title="Error notification"
                text="Notification message. Here will be information."
                className="margin-b-48"
            />
            <Notice
                noticeType={ENoticeType.SUCCESS}
                title="Success notification"
                text="Notification message. Here will be information."
                className="margin-b-48"
            />
        </>
    )
}