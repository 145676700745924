import React from "react";
import {makeSvgIconFromPathSmall} from "./SvgIcons";

export const IconXSquareSmall = makeSvgIconFromPathSmall(
    "x-square-small",
    <>
        <path d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 6L10 10" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 6L6 10" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconArrowRightSmall = makeSvgIconFromPathSmall(
    "arrow-right-small",
    <>
        <path d="M8 3.33337L12.6667 8.00004L8 12.6667" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.33301 8H12.6663" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPlusSmall = makeSvgIconFromPathSmall(
    "plus-small",
    <>
        <path d="M8 3.33337V12.6667" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.33301 8H12.6663" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

//TODO нужен ли?
export const IconLoaderSmall = makeSvgIconFromPathSmall(
    "loader-small",
    <>
        <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM2.13496 8C2.13496 11.2392 4.76083 13.865 8 13.865C11.2392 13.865 13.865 11.2392 13.865 8C13.865 4.76083 11.2392 2.13496 8 2.13496C4.76083 2.13496 2.13496 4.76083 2.13496 8Z" fill="url(#paint0_angular_145_578)"/>
        <defs>
            <radialGradient id="paint0_angular_145_578" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8 8) rotate(90) scale(8)">
                <stop stopColor="#00E695"/>
                <stop offset="1" stopColor="#DFC59E" stopOpacity="0"/>
            </radialGradient>
        </defs>
    </>
);

export const IconOkCircleSmall = makeSvgIconFromPathSmall(
    "ok-circle-small",
    <>
        <path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00004C14.6663 4.31814 11.6816 1.33337 7.99967 1.33337C4.31778 1.33337 1.33301 4.31814 1.33301 8.00004C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.7999 6L10.3112 6.66667L9.4668 7.81818L7.66654 10.1818L7.35543 9.86364L7.04431 9.54545L6.42209 8.90909L5.97765 8.45455L5.5332 8" stroke="#00E695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconWarningSmall = makeSvgIconFromPathSmall(
    "warning-small",
    <>
        <path d="M6.85953 2.57335L1.21286 12C1.09644 12.2016 1.03484 12.4302 1.03418 12.663C1.03353 12.8958 1.09385 13.1248 1.20914 13.327C1.32443 13.5293 1.49068 13.6978 1.69133 13.8159C1.89199 13.934 2.12006 13.9975 2.35286 14H13.6462C13.879 13.9975 14.1071 13.934 14.3077 13.8159C14.5084 13.6978 14.6746 13.5293 14.7899 13.327C14.9052 13.1248 14.9655 12.8958 14.9649 12.663C14.9642 12.4302 14.9026 12.2016 14.7862 12L9.13953 2.57335C9.02068 2.37742 8.85334 2.21543 8.65366 2.103C8.45397 1.99058 8.22868 1.93152 7.99953 1.93152C7.77037 1.93152 7.54508 1.99058 7.3454 2.103C7.14571 2.21543 6.97837 2.37742 6.85953 2.57335V2.57335Z" stroke="#FEC705" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 6V8.66667" stroke="#FEC705" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 11.3334H8.00667" stroke="#FEC705" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconErrorSmall = makeSvgIconFromPathSmall(
    "error-small",
    <>
        <path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00004C14.6663 4.31814 11.6816 1.33337 7.99967 1.33337C4.31778 1.33337 1.33301 4.31814 1.33301 8.00004C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 6L6 10" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 6L10 10" stroke="#E60045" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconOkSmall = makeSvgIconFromPathSmall(
    "ok-small",
    <>
        <path d="M13.3337 4L6.00033 11.3333L2.66699 8" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);