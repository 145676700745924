export function generateUuid(): string {
    let s = Array.from(Array(36));

    const itoh = "0123456789ABCDEF";

    s = s.map(() => Math.floor(Math.random() * itoh.length));

    s[14] = 4;
    s[19] = (s[19] & 3) | 8;

    s = s.map((v) => itoh[v]);

    s[8] = s[13] = s[18] = s[23] = "-";

    return s.join("");
}