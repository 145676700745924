import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import "./index.scss";
import {TextExample} from "./example/TextExample";
import {IconExample} from "./example/IconExample";
import {ButtonExample} from "./example/ButtonExample";
import {TextFieldExample} from "./example/TextFieldExample";
import {NoticeExample} from "./example/NoticeExample";
import {TypicalHeaderExample} from "./example/TypicalHeaderExample";
import {Background} from "./lib/core/Background/Background";
import {SelectControlsExample} from "./example/SelectControlsExample";
import {LoaderExample} from "./example/LoaderExample";
import {TabExample} from "./example/TabExample";
import ModalProvider from "./lib/core/Modal/context/ModalContext";
import {ModalExample} from "./example/ModalExample";
import {TooltipExample} from "./example/TooltipExample";
import {MasterExample} from "./example/MasterExample";
import {TypicalFooterExample} from "./example/TypicalFooterExample";
import {ChartExample} from "./example/ChartExample";
import {ImageExample} from "./example/ImageExample";

function App() {
    return (
        <Router>
            <ModalProvider>
                <Background/>

                <div className="app">
                    <TypicalHeaderExample />

                    <div className="content">
                        <TextExample />

                        <IconExample />

                        <ButtonExample />

                        <TextFieldExample />

                        <NoticeExample />

                        <SelectControlsExample />

                        <LoaderExample />

                        <TabExample />

                        <TooltipExample />

                        <MasterExample />

                        <ModalExample />

                        <ChartExample />

                        <ImageExample />
                    </div>

                    <TypicalFooterExample />
                </div>
            </ModalProvider>
        </Router>
    );
}

export default App;

declare global {
    interface SVGAnimateElement {
        beginElement(): void;

        endElement(): void;
    }
}
