import {makeSvgIconFromPath} from "./SvgIcons";
import React from "react";

export const IconAtSign = makeSvgIconFromPath(
    "at-sign",
    <>
        <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 8.00011V13.0001C16 13.7958 16.3161 14.5588 16.8787 15.1214C17.4413 15.684 18.2044 16.0001 19 16.0001C19.7957 16.0001 20.5587 15.684 21.1213 15.1214C21.6839 14.5588 22 13.7958 22 13.0001V12.0001C21.9999 9.74314 21.2362 7.55259 19.8333 5.78464C18.4303 4.0167 16.4706 2.77534 14.2726 2.26241C12.0747 1.74948 9.76794 1.99515 7.72736 2.95948C5.68677 3.9238 4.03241 5.55007 3.03327 7.57383C2.03413 9.5976 1.74898 11.8998 2.22418 14.1062C2.69938 16.3126 3.90699 18.2933 5.65064 19.7264C7.39429 21.1594 9.57144 21.9605 11.8281 21.9993C14.0847 22.0381 16.2881 21.3124 18.08 19.9401" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconCodesandbox = makeSvgIconFromPath(
    "codesandbox",
    <>
        <path d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 4.20996L12 6.80996L16.5 4.20996" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 19.79V14.6L3 12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 12L16.5 14.6V19.79" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.26953 6.95996L11.9995 12.01L20.7295 6.95996" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 22.08V12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconChrome = makeSvgIconFromPath(
    "chrome",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.17 8H12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.9502 6.06006L8.5402 14.0001" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.8799 21.94L15.4599 14" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconGlobe = makeSvgIconFromPath(
    "globe",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 12H22" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconFacebook = makeSvgIconFromPath(
    "facebook",
    <>
        <path d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconDribbble = makeSvgIconFromPath(
    "dribbble",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.56 2.75C12.93 8.78 14.58 12.17 16.59 20.47M19.13 5.09C15.41 9.44 10.19 10.75 2.25 10.94M21.75 12.84C18.25 11.91 15.12 12.02 12.81 12.84C10.23 13.76 7.8 15.7 5.37 19.16" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconTwitch = makeSvgIconFromPath(
    "twitch",
    <>
        <path d="M11 11V7M16 11V7M21 2H3V18H8V22L12 18H17L21 14V2Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconTwitter = makeSvgIconFromPath(
    "twitter",
    <>
        <path d="M23 3.00005C22.0424 3.67552 20.9821 4.19216 19.86 4.53005C19.2577 3.83756 18.4573 3.34674 17.567 3.12397C16.6767 2.90121 15.7395 2.95724 14.8821 3.2845C14.0247 3.61176 13.2884 4.19445 12.773 4.95376C12.2575 5.71308 11.9877 6.61238 12 7.53005V8.53005C10.2426 8.57561 8.50127 8.18586 6.93101 7.39549C5.36074 6.60513 4.01032 5.43868 3 4.00005C3 4.00005 -1 13 8 17C5.94053 18.398 3.48716 19.099 1 19C10 24 21 19 21 7.50005C20.9991 7.2215 20.9723 6.94364 20.92 6.67005C21.9406 5.66354 22.6608 4.39276 23 3.00005V3.00005Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconTv = makeSvgIconFromPath(
    "tv",
    <>
        <path d="M20 7H4C2.89543 7 2 7.89543 2 9V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V9C22 7.89543 21.1046 7 20 7Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 2L12 7L7 2" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconYoutube = makeSvgIconFromPath(
    "youtube",
    <>
        <path d="M22.5396 6.42C22.4208 5.94541 22.1789 5.51057 21.8382 5.15941C21.4976 4.80824 21.0703 4.55318 20.5996 4.42C18.8796 4 11.9996 4 11.9996 4C11.9996 4 5.1196 4 3.3996 4.46C2.92884 4.59318 2.50157 4.84824 2.16094 5.19941C1.82031 5.55057 1.57838 5.98541 1.4596 6.46C1.14481 8.20556 0.990831 9.97631 0.999595 11.75C0.988374 13.537 1.14236 15.3213 1.4596 17.08C1.59055 17.5398 1.8379 17.9581 2.17774 18.2945C2.51758 18.6308 2.93842 18.8738 3.3996 19C5.1196 19.46 11.9996 19.46 11.9996 19.46C11.9996 19.46 18.8796 19.46 20.5996 19C21.0703 18.8668 21.4976 18.6118 21.8382 18.2606C22.1789 17.9094 22.4208 17.4746 22.5396 17C22.852 15.2676 23.0059 13.5103 22.9996 11.75C23.0108 9.96295 22.8568 8.1787 22.5396 6.42V6.42Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.75 15.02L15.5 11.75L9.75 8.47998V15.02Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconMonitor = makeSvgIconFromPath(
    "monitor",
    <>
        <path d="M20 3H4C2.89543 3 2 3.89543 2 5V15C2 16.1046 2.89543 17 4 17H20C21.1046 17 22 16.1046 22 15V5C22 3.89543 21.1046 3 20 3Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 21H16" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 17V21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconCompass = makeSvgIconFromPath(
    "compass",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.2398 7.76001L14.1198 14.12L7.75977 16.24L9.87977 9.88001L16.2398 7.76001Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconInstagram = makeSvgIconFromPath(
    "instagram",
    <>
        <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.9997 11.3701C16.1231 12.2023 15.981 13.0523 15.5935 13.7991C15.206 14.5459 14.5929 15.1515 13.8413 15.5297C13.0898 15.908 12.2382 16.0397 11.4075 15.906C10.5768 15.7723 9.80947 15.3801 9.21455 14.7852C8.61962 14.1903 8.22744 13.4229 8.09377 12.5923C7.96011 11.7616 8.09177 10.91 8.47003 10.1584C8.84829 9.40691 9.45389 8.7938 10.2007 8.4063C10.9475 8.0188 11.7975 7.87665 12.6297 8.00006C13.4786 8.12594 14.2646 8.52152 14.8714 9.12836C15.4782 9.73521 15.8738 10.5211 15.9997 11.3701Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.5 6.5H17.51" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconLifeBuoy = makeSvgIconFromPath(
    "life-buoy",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.92969 4.92993L9.16969 9.16993" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.8301 14.8301L19.0701 19.0701" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.8301 9.16993L19.0701 4.92993" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.8301 9.16989L18.3601 5.63989" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.92969 19.0701L9.16969 14.8301" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconLinkedin = makeSvgIconFromPath(
    "linkedin",
    <>
        <path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 9H2V21H6V9Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconBluetooth = makeSvgIconFromPath(
    "bluetooth",
    <>
        <path d="M6.5 6.5L17.5 17.5L12 23V1L17.5 6.5L6.5 17.5" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconAirplay = makeSvgIconFromPath(
    "box",
    <>
        <path d="M5 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H20C20.5304 3 21.0391 3.21071 21.4142 3.58579C21.7893 3.96086 22 4.46957 22 5V15C22 15.5304 21.7893 16.0391 21.4142 16.4142C21.0391 16.7893 20.5304 17 20 17H19" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 15L17 21H7L12 15Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);