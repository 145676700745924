import React, {useCallback} from "react";
import {HTMLAttributes, ReactNode} from "react";
import classNames from "classnames";
import "./checkbox.scss";
import {TextM} from "../../text/Text/Text";

type Props = {
    label?: ReactNode;
    checked: boolean;
    onAction: (checked: boolean) => void;
    disabled?: boolean;
} & HTMLAttributes<HTMLInputElement>;

export function Checkbox({label, checked, onAction, disabled, className, children, ...pp}: Props) {
    const onClick = useCallback((e: React.MouseEvent) => {
        if (!disabled) {
            onAction(!checked);
        }
        e.preventDefault();
        e.stopPropagation();
    }, [disabled, checked, onAction]);

    return (
        <div className={classNames("flex-row pointer width-fit-content", className)} {...pp} onClick={onClick}>
            <div className={classNames("checkbox margin-r-8", {default: !disabled && !checked, disabled, checked})}>
                <input type="checkbox" checked={checked || false} onChange={() => {}} disabled={disabled} />

                {checked ? (
                    <svg className="check-item">
                        <path d="M12.3327 1L4.99935 8.33333L1.66602 5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                ) : null}
            </div>

            {label || children ? (
                <TextM className="margin-no">{label || children}</TextM>
            ) : null}
        </div>
    );
}