import React, {useContext, useEffect} from "react";
import {ChartContext} from "../context/ChartContext";
import * as d3 from "d3";
import "./chart-components.scss";

export enum EAxisPosition {
    LEFT,
    RIGHT,
    BOTTOM,
    TOP,
}

type Props = {
    axisPosition?: EAxisPosition
}

export const ChartAxisX = React.memo(function ChartAxisX({axisPosition}: Props) {
    const {scaleFuncX, height, svg} = useContext(ChartContext);

    useEffect(() => {
        svg.append && svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(axisPosition == EAxisPosition.TOP ? d3.axisTop(scaleFuncX) : d3.axisBottom(scaleFuncX))
            .attr("class", "axis");
    }, []);
    return (
        <></>
    );
})

export const ChartAxisY = React.memo(function ChartAxisY({axisPosition}: Props) {
    const {scaleFuncY, svg} = useContext(ChartContext);
    useEffect(() => {
        svg.append && svg.append("g")
            .call(axisPosition == EAxisPosition.RIGHT ? d3.axisRight(scaleFuncY) : d3.axisLeft(scaleFuncY))
            .attr("class", "axis");
    }, []);
    return (
        <></>
    );
})