import React from "react";
import {HTMLAttributes} from "react";
import classNames from "classnames";
import {TextXS} from "../../text/Text/Text";

type Props = {
    src: string;
    alt: string;
    name?: string;
    imgClassName?: string;
    onClick?: () => void;
} & HTMLAttributes<HTMLDivElement>;

function Image({src, alt, name, className, imgClassName, onClick, ...pp}: Props) {
    return (
        <div className={classNames("flex-col flex-align-center", className)} {...pp}>
            <img src={src} alt={alt} className={imgClassName} onClick={onClick}/>
            <TextXS className="grey">{name}</TextXS>
        </div>
    )
}

export default React.memo(Image);