import React, {DetailsHTMLAttributes} from "react";
import "./section.scss";
import classNames from "classnames";

type Props = {} & DetailsHTMLAttributes<HTMLDivElement>;

export const Section = React.memo(function Section({children, className, ...pp}: Props) {
    return (
        <div className={classNames("section", className)} {...pp}>
            {children}
        </div>
    );
});