import classNames from "classnames";
import "./button.scss";
import React, {AnchorHTMLAttributes, ButtonHTMLAttributes} from "react";

export enum EButtonStyle {
    PRIMARY = "primary_style",
    SECONDARY = "secondary_style",
    TERTIARY = "tertiary_style",
}

export enum EButtonSize {
    LARGE = "large_size",
    MEDIUM = "medium_size",
    NORMAL = "normal_size",
    SMALL = "small_size",
    EXTRA_SMALL = "extra_small_size",
}

export enum EButtonWidth {
    AUTO = "auto_width",
    FULL_WIDTH = "full_width",
}

type Props = {
    label?: React.ReactNode,
    buttonStyle?: EButtonStyle,
    buttonSize?: EButtonSize,
    buttonWidth?: EButtonWidth,
    disabled?: boolean,
    loading?: boolean,
    onClick?: () => void,
} & Partial<ButtonHTMLAttributes<HTMLButtonElement> & AnchorHTMLAttributes<HTMLAnchorElement>>;

export type ButtonProps = Props;

export function Button({label, buttonStyle, buttonSize, buttonWidth, disabled, loading, children, className, ...pp}: Props) {
    const loadingLine = 5;
    return (
        <button
            {...pp}
            className={classNames(
                className,
                "button",
                `button-${(buttonSize || EButtonSize.NORMAL)}`,
                `button-${(buttonStyle || EButtonStyle.PRIMARY)}`,
                `button-${(buttonWidth || EButtonWidth.AUTO)}`,
                {disabled, loading}
            )}
        >
            {children || label || "Кнопка"}
            {loading ? (
                <svg className="loading" strokeWidth={loadingLine} key={Math.random()}>
                    <rect x={`${loadingLine}px`} y={`${loadingLine}px`} width={`calc(100% - ${loadingLine * 2}px)`} height={`calc(100% - ${loadingLine * 2}px)`} rx={8} ry={8}/>
                </svg>
            ) : null}
        </button>
    );
}