import {makeSvgIconFromPath} from "./SvgIcons";
import React from "react";

export const IconPercent = makeSvgIconFromPath(
    "percent",
    <>
        <path d="M19 5L5 19" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.5 9C7.88071 9 9 7.88071 9 6.5C9 5.11929 7.88071 4 6.5 4C5.11929 4 4 5.11929 4 6.5C4 7.88071 5.11929 9 6.5 9Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.5 20C18.8807 20 20 18.8807 20 17.5C20 16.1193 18.8807 15 17.5 15C16.1193 15 15 16.1193 15 17.5C15 18.8807 16.1193 20 17.5 20Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconDatabase = makeSvgIconFromPath(
    "database",
    <>
        <path d="M12 8C16.9706 8 21 6.65685 21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5C3 6.65685 7.02944 8 12 8Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 12C21 13.66 17 15 12 15C7 15 3 13.66 3 12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 5V19C3 20.66 7 22 12 22C17 22 21 20.66 21 19V5" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconDisc = makeSvgIconFromPath(
    "disc",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconCreditCard = makeSvgIconFromPath(
    "credit-card",
    <>
        <path d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 10H23" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconDivide = makeSvgIconFromPath(
    "divide",
    <>
        <path d="M12 8C13.1046 8 14 7.10457 14 6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6C10 7.10457 10.8954 8 12 8Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 12H19" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconDollarSign = makeSvgIconFromPath(
    "dollar-sign",
    <>
        <path d="M12 1V23" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 5H9.5C8.57174 5 7.6815 5.36875 7.02513 6.02513C6.36875 6.6815 6 7.57174 6 8.5C6 9.42826 6.36875 10.3185 7.02513 10.9749C7.6815 11.6313 8.57174 12 9.5 12H14.5C15.4283 12 16.3185 12.3687 16.9749 13.0251C17.6313 13.6815 18 14.5717 18 15.5C18 16.4283 17.6313 17.3185 16.9749 17.9749C16.3185 18.6313 15.4283 19 14.5 19H6" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconClipboard = makeSvgIconFromPath(
    "clipboard",
    <>
        <path d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconCircle = makeSvgIconFromPath(
    "circle",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconTerminal = makeSvgIconFromPath(
    "terminal",
    <>
        <path d="M4 17L10 11L4 5" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 19H20" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconKey = makeSvgIconFromPath(
    "key",
    <>
        <path d="M21.0003 2L19.0003 4M21.0003 2L15.5003 7.5M15.5003 7.5L18.5003 10.5L22.0003 7L19.0003 4M15.5003 7.5L19.0003 4M11.3903 11.61C11.9066 12.1195 12.3171 12.726 12.598 13.3948C12.879 14.0635 13.0249 14.7813 13.0273 15.5066C13.0297 16.232 12.8887 16.9507 12.6122 17.6213C12.3357 18.2919 11.9293 18.9012 11.4164 19.4141C10.9035 19.9271 10.2942 20.3334 9.62358 20.6099C8.95296 20.8864 8.23427 21.0275 7.50891 21.025C6.78354 21.0226 6.06582 20.8767 5.39707 20.5958C4.72831 20.3148 4.12174 19.9043 3.61227 19.388C2.6104 18.3507 2.05604 16.9614 2.06857 15.5193C2.0811 14.0772 2.65953 12.6977 3.67927 11.678C4.69902 10.6583 6.07849 10.0798 7.52057 10.0673C8.96265 10.0548 10.352 10.6091 11.3893 11.611L11.3903 11.61Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconGift = makeSvgIconFromPath(
    "gift",
    <>
        <path d="M20 12V22H4V12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 7H2V12H22V7Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 22V7" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconPackage = makeSvgIconFromPath(
    "package",
    <>
        <path d="M16.5 9.39996L7.5 4.20996" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.26953 6.95996L11.9995 12.01L20.7295 6.95996" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 22.08V12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconBriefcase = makeSvgIconFromPath(
    "briefcase",
    <>
        <path d="M20 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconBox = makeSvgIconFromPath(
    "box",
    <>
        <path d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.26953 6.95996L11.9995 12.01L20.7295 6.95996" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 22.08V12" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);