import {ModalWindow, ModalWindowProps} from "../ModalWindow";
import React, {HTMLAttributes, useCallback, useState} from "react";
import {ModalContextType} from "./@types/ModalContext";
import {generateUuid} from "../../Utils";
import "../modal.scss";

const ModalContextDefault = {
    pushModalWindow: (modalProps: ModalWindowProps) => {},
    closeLastModalWindow: () => {},
    closeAllModalWindows: () => {},
};

export const ModalContext = React.createContext<ModalContextType>(ModalContextDefault);

const ModalProvider: React.FC<HTMLAttributes<HTMLElement>> = ( {children}: HTMLAttributes<HTMLElement> ) => {
    const [stack, setStack] = useState<Array<StackElement>>([]);

    const pushModalWindow = useCallback((modalProps: ModalWindowProps) => {
        setStack((stack) => {
            return [
                ...stack,
                {
                    id: generateUuid(),
                    modalProps: modalProps
                },
            ];
        });
    }, []);

    const closeLastModalWindow = () => setStack((stack) => stack.slice(0, -1));
    const closeAllModalWindows = () => setStack([]);

    return (
        <ModalContext.Provider value={{pushModalWindow, closeLastModalWindow, closeAllModalWindows}}>
            {children}
            {!!stack.length && (
                <div id="modal-provider_back"><ModalWindow {...stack[stack.length - 1].modalProps} /></div>
            )}
        </ModalContext.Provider>
    );
};

export default ModalProvider;

type StackElement = {
    id: string;
    modalProps: ModalWindowProps;
} & ModalWindowProps;