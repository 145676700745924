import React, {useState} from "react";
import {ETextFieldState, TextField} from "../lib/components/controls/Field/TextField";
import {H2} from "../lib/components/text/Header/Header";
import {IconSearch} from "../lib/components/icons/NavigationIcons";
import {IconPaperclip} from "../lib/components/icons/ActionIcons";
import {PasswordField} from "../lib/components/controls/Field/PasswordField";
import {NumberField} from "../lib/components/controls/Field/NumberField";
import {ETextFieldTypeRegex, ValidationTextField} from "../lib/components/controls/Field/ValidationTextField";

export function TextFieldExample() {
    const [value, setValue] = useState<string>("");
    return(
        <div className="margin-b-80 margin-all-b-16">
            <H2>Fields</H2>
            <TextField
                value={value}
                onChange={setValue}
                label="label"
                icon={<IconSearch/>}
                onIconClick={() => console.log("IconClick")}
                fieldState={ETextFieldState.DEFAULT}
            />
            <TextField
                value="value"
                onChange={setValue}
                label="label"
                icon={<IconSearch/>}
                onIconClick={() => console.log("IconClick")}
                fieldState={ETextFieldState.DISABLED}
            />
            <TextField
                value={value}
                onChange={setValue}
                label="label"
                icon={<IconSearch/>}
                onIconClick={() => console.log("IconClick")}
                fieldState={ETextFieldState.WARNING}
                fieldStateText="Warning"
            />
            <TextField
                value={value}
                onChange={setValue}
                label="label"
                icon={<IconPaperclip/>}
                onIconClick={() => console.log("IconClick")}
                fieldState={ETextFieldState.ERROR}
                fieldStateText="Error"
            />
            <TextField
                value={value}
                onChange={setValue}
                label="label"
                icon={<IconPaperclip/>}
                onIconClick={() => console.log("IconClick")}
                fieldState={ETextFieldState.SUCCESS}
                fieldStateText="Success"
            />
            <PasswordField
                value={value}
                onChange={setValue}
                label="Password"
                onForgotPasswordClick={() => console.log("ForgotPassword")}
            />
            <NumberField
                value={value}
                label="Number"
                useFloat={true}
                onChange={setValue}
            />
            <ValidationTextField
                value={value}
                onChange={setValue}
                label="FIO"
                textFieldRegex={ETextFieldTypeRegex.FIO}
            />
            <ValidationTextField
                value={value}
                onChange={setValue}
                label="EMAIL"
                textFieldRegex={ETextFieldTypeRegex.EMAIL}
            />
            <ValidationTextField
                value={value}
                onChange={setValue}
                label="LOGIN"
                textFieldRegex={ETextFieldTypeRegex.LOGIN}
            />
            <ValidationTextField
                value={value}
                onChange={setValue}
                label="PHONE"
                textFieldRegex={ETextFieldTypeRegex.PHONE}
            />
            <ValidationTextField
                value={value}
                onChange={setValue}
                label="PASSWORD"
                textFieldRegex={ETextFieldTypeRegex.PASSWORD}
            />
        </div>
    )
}