import React, {DetailsHTMLAttributes, ReactNode} from "react";
import "./typical-footer.scss";
import classNames from "classnames";

type Props = {
    mainBlocks: ReactNode;
    additionalBlock?: ReactNode;
} & DetailsHTMLAttributes<HTMLDivElement>;

export const TypicalFooter = React.memo(function TypicalFooter({mainBlocks, additionalBlock, className, ...pp}: Props) {
    return (
        <div className={classNames("typical-footer flex-col-center", className)} {...pp}>
            <div className="content">
                <div className="flex-row flex-j-between margin-b-8">
                    {mainBlocks}
                </div>
                {additionalBlock}
            </div>
        </div>
    );
});