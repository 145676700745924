import React from "react";
import classNames from "classnames";
import "./tab.scss";

export type TabHeaderComponentProps = {
    tabId: string;
    tabName: React.ReactNode;
};

type Props = {
    activeTabId: string;
    disabled?: boolean;
    tabComponent: React.ComponentElement<TabHeaderComponentProps, any>;
    onTabClick: (tabId: string) => void;
};

export function TabHeaderItem({activeTabId, disabled, tabComponent, onTabClick}: Props) {
    const {tabId, tabName} = tabComponent.props;
    const isActive = tabId == activeTabId && !disabled;

    const onTabBlockClick = () => {
        if (disabled) {
            return;
        }
        onTabClick(tabId);
    }

    return (
        <div className={classNames("tab-header-item pointer", {"active": isActive, "disabled": disabled})} onClick={onTabBlockClick}>
            {tabName}
        </div>
    );
}