import React, {HTMLAttributes} from "react";
import classNames from "classnames";
import {H4} from "../../text/Header/Header";
import {TextM} from "../../text/Text/Text";
import "./notice.scss"
import {IconErrorCircle, IconInfo, IconOkColorCircle, IconWarning} from "../../icons/InfoIcons";

export enum ENoticeType {
    INFO = "info_type",
    WARNING = "warning_type",
    ERROR = "error_type",
    SUCCESS = "success_type",
}

type Props = {
    noticeType?: ENoticeType,
    title?: React.ReactNode,
    text?: React.ReactNode,
    isFullWidth?: boolean,
} & HTMLAttributes<HTMLElement>;

const IconMapping: {[key in ENoticeType]: React.ReactNode} = {
    [ENoticeType.INFO]: <IconInfo/>,
    [ENoticeType.WARNING]: <IconWarning/>,
    [ENoticeType.ERROR]: <IconErrorCircle/>,
    [ENoticeType.SUCCESS]: <IconOkColorCircle/>,
};

export function Notice({noticeType, title, text, isFullWidth, className, ...pp}: Props) {
    return (
        <div
            {...pp}
            className={classNames(
                className,
                "notice flex-row-center",
                `notice-${(noticeType || ENoticeType.INFO)}`,
                {"full-width": isFullWidth}
            )}
        >
            {IconMapping[noticeType || ENoticeType.INFO]}
            <div>
                <H4 className="margin-no-vertical">{title}</H4>
                <TextM className="margin-no-vertical">{text}</TextM>
            </div>
        </div>
    )
}