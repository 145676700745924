import React from "react";
import {H2} from "../lib/components/text/Header/Header";
import Loader, {ELoaderSize, ELoaderType} from "../lib/components/info/Loader/Loader";

export function LoaderExample() {
    return (
        <>
            <H2>Loaders</H2>
            <div className="margin-all-b-8">
                <Loader loaderType={ELoaderType.BLUE_TYPE} loaderSize={ELoaderSize.BIG_SIZE} header="Загрузка..." description="Попробуйте позднее"/>
                <Loader loaderType={ELoaderType.GREEN_TYPE} loaderSize={ELoaderSize.BIG_SIZE} header="Загрузка..."/>
                <Loader loaderType={ELoaderType.BLUE_TYPE} loaderSize={ELoaderSize.BIG_SIZE} description="Попробуйте позднее"/>
                <Loader loaderType={ELoaderType.BLUE_TYPE} loaderSize={ELoaderSize.SMALL_SIZE} description="Попробуйте позднее"/>
                <Loader loaderType={ELoaderType.GREEN_TYPE} loaderSize={ELoaderSize.SMALL_SIZE}/>
                <Loader loaderType={ELoaderType.BLUE_TYPE} loaderSize={ELoaderSize.EXTRA_SMALL_SIZE}/>
                <Loader loaderType={ELoaderType.GREEN_TYPE} loaderSize={ELoaderSize.EXTRA_SMALL_SIZE} className="margin-b-48"/>
            </div>
        </>
    );
}