import React, {useContext, useEffect} from "react";
import {ChartContext} from "../context/ChartContext";
import * as d3 from "d3";
import "./chart-components.scss";

export const ChartArea = React.memo(function ChartArea() {
    const {data, scaleFuncX, scaleFuncY, curve, svg} = useContext(ChartContext);

    useEffect(() => {
        const lg = svg.append("defs").append("linearGradient")
            .attr("id", "area")
            .attr("x1", "0%")
            .attr("y1", "100%")
            .attr("x2", "0%")
            .attr("y2", "0%")
            .attr("gradientUnits", "userSpaceOnUse");

        lg.append("stop")
            .attr("class", "begin");

        lg.append("stop")
            .attr("class", "end")
            .attr("offset", "1");

        const max = d3.max(data, d => +d[1])!!;
        const min = d3.min(data, d => +d[1])!!;
        const indent = (max - min) * 0.1;
        const y0 = min - indent;

        const area = d3.area()
            .x(d => scaleFuncX(d[0]))
            .y0(scaleFuncY(y0))
            .y1(d => scaleFuncY(d[1]));

        if (curve) {
            area.curve(d3.curveCardinal);
        }

        // Add the area
        svg.append("path")
            .datum(data)
            .attr("fill", "url(#area)")
            .attr("fill-opacity", 0.8)
            .attr("opacity", "0.5")
            .attr("stroke", "none")
            .attr("d", area);
    }, []);

    return (
        <></>
    );
});
