import React, {ReactElement, SVGAttributes} from "react";
import classNames from "classnames";
import "./icons.scss"

export function makeSvgIconFromPath(name: string, pathElement: ReactElement) {
    return React.memo(function SvgIcon({className, ...props}: SVGAttributes<SVGElement>) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={classNames(`svg-icon icon__${name}`, className)} {...props}>
                {pathElement}
            </svg>
        );
    });
}

export function makeSvgIconFromPathSmall(name: string, pathElement: ReactElement) {
    return React.memo(function SvgIcon({className, ...props}: SVGAttributes<SVGElement>) {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className={classNames(`svg-icon icon__${name}`, className)} {...props}>
                {pathElement}
            </svg>
        );
    });
}