import React, {ReactNode, useCallback, useState} from "react";
import classNames from "classnames";
import "./text-field.scss";
import {
    IconErrorSmall,
    IconLoaderSmall,
    IconOkCircleSmall,
    IconWarningSmall
} from "../../icons/SmallIcons";

export enum ETextFieldState {
    DEFAULT = "default",
    DISABLED = "disabled",
    WARNING = "warning",
    ERROR = "error",
    LOADING = "loading",
    SUCCESS = "success",
}

export type TextFieldProps = {
    inputType?: string;
    label?: string;
    value?: string;
    onChange: (value: string) => void;
    fieldState?: ETextFieldState;
    fieldStateText?: string;

    icon?: ReactNode;
    onIconClick?: () => void;

    additionalBlock?: ReactNode;
}

export function TextField({inputType, label, value, onChange, fieldState, fieldStateText, icon, onIconClick, additionalBlock, ...pp}: TextFieldProps) {
    const [focused, setFocused] = useState<boolean>(false);

    const id = `tf-${(Math.random() * 1000000).toFixed(0)}`;

    let fieldStateIcon: ReactNode | null
    switch (fieldState) {
        case ETextFieldState.DEFAULT:
        case ETextFieldState.DISABLED:
            fieldStateIcon = null;
            break;
        case ETextFieldState.WARNING:
            fieldStateIcon = <IconWarningSmall className="margin-r-8"/>;
            break;
        case ETextFieldState.ERROR:
            fieldStateIcon = <IconErrorSmall className="margin-r-8"/>;
            break;
        case ETextFieldState.LOADING:
            fieldStateIcon = <IconLoaderSmall className="margin-r-8"/>;
            break;
        case ETextFieldState.SUCCESS:
            fieldStateIcon = <IconOkCircleSmall className="margin-r-8"/>;
            break;
        default:
            fieldStateIcon = null;
            break;
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value || "";
        onChange(eventValue);
    };

    let refInput: HTMLInputElement | null = null;

    const onIconContainerClick = useCallback(
        (e: React.MouseEvent) => {
            if (fieldState == ETextFieldState.DISABLED) {
                return;
            }

            refInput?.blur();
            onIconClick && onIconClick();
            e.stopPropagation();
        },
        [refInput, onIconClick],
    );

    return (
        <div className="text-field-block">
            <div
                className={classNames(
                    "text-field",
                    `text-field-${(fieldState || ETextFieldState.DEFAULT)}`,
                    {
                        focused: focused,
                        valued: !!value,
                        "no-label": !label,
                    })}
                onClick={() => refInput?.focus()}
                {...pp}
            >
                {label && <label htmlFor={id}>{label}</label>}
                <input
                    id={id}
                    type={inputType || "text"}
                    value={value}
                    onChange={handleChange}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    ref={(ref) => (refInput = ref)}
                    disabled={fieldState == ETextFieldState.DISABLED}
                />
                <div className="icon-container" onClick={onIconContainerClick} >
                    {icon}
                </div>
            </div>
            {fieldStateText && !additionalBlock ? (
                <div className="field-state-text-block flex-row-center margin-t-8">
                    {fieldStateIcon}
                    {fieldStateText}
                </div>
            ) : null}
            {additionalBlock}
        </div>
    )
}