import React from "react";
import {TypicalFooter} from "../lib/core/TypicalFooter/TypicalFooter";
import {H4} from "../lib/components/text/Header/Header";
import {TextM, TextS} from "../lib/components/text/Text/Text";

export function TypicalFooterExample() {
    const mainBlocks = (
        <>
            <div>
                <TextM className="margin-no">Разработчик</TextM>
                <H4 className="margin-no">Кузнецов Ярослав Андреевич</H4>
                <TextM className="margin-no">Контактные данные</TextM>
                <H4 className="margin-no">mr.yaros@yandex.ru</H4>
            </div>
            <div>
                <TextM className="margin-no">Научный руководитель</TextM>
                <H4 className="margin-no">Максимов Владимир Петрович</H4>
            </div>
            <div className="flex-col">
                <a href="https://design.mryaros.ru" target="_blank">Дизайн-система</a>
                <a href="https://platform.mryaros.ru" target="_blank">Платформа</a>
            </div>
            <div className="flex-col">
                <a href="https://design.mryaros.ru" target="_blank">О проекте</a>
                <a href="https://platform.mryaros.ru" target="_blank">Платформа</a>
            </div>
        </>
    );
    const additionalBlock = (
        <TextS className="margin-t-24">
            Продолжая использовать наш сайт, вы даете согласие на обработку файлов Cookies и других пользовательских данных
        </TextS>
    );
    return <TypicalFooter mainBlocks={mainBlocks} additionalBlock={additionalBlock} />;
}