import React from "react";
import {H2} from "../lib/components/text/Header/Header";
import {Tooltip} from "../lib/components/info/Tooltip/Tooltip";
import {IconSearch} from "../lib/components/icons/NavigationIcons";

export function TooltipExample() {
    return (
        <>
            <H2>Tooltip</H2>
            <Tooltip tooltip="Подсказка — элемент графического интерфейса, служит дополнительным средством обучения пользователя." >
                <IconSearch />
            </Tooltip>
        </>
    );
}