import {ETextFieldState, TextField, TextFieldProps} from "./TextField";
import React, {useEffect, useState} from "react";
import {checkTextFieldValue, ETextFieldTypeRegex} from "./ValidationTextField";

type Props = {
    useFloat?: boolean;
    onErrorValue?: () => void;
} & TextFieldProps;

export function NumberField({value, useFloat, onErrorValue, fieldState, fieldStateText, ...pp}: Props) {
    const [isValueValid, setIsValueValid] = useState<boolean>(true);

    useEffect(() => {
        const regex = useFloat ? ETextFieldTypeRegex.NUMBER_FLOAT : ETextFieldTypeRegex.NUMBER;
        setIsValueValid(checkTextFieldValue(regex, value));
        onErrorValue && onErrorValue();
    }, [value]);

    return (
        <TextField
            value={value}
            fieldState={isValueValid ? fieldState : ETextFieldState.ERROR}
            fieldStateText={isValueValid ? fieldStateText : "Поле может содержать только числа"}
            {...pp}
        />
    )
}