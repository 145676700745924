import {Link} from "react-router-dom";
import React from "react";
// @ts-ignore
import MrYarosLogo from "../lib/core/TypicalHeader/MrYarosLogo.png";
import {TypicalHeader} from "../lib/core/TypicalHeader/TypicalHeader";
import {Button} from "../lib/components/controls/Button/Button";

export function TypicalHeaderExample() {
    const headerLinks = (<>
        <Link to="/">ГЛАВНАЯ</Link>
        <Link to="/company" className="link-block">О КОМПАНИИ</Link>
        <Link to="/news" className="link-block">НОВОСТИ</Link>
        <Link to="/contacts" className="link-block">КОНТАКТЫ</Link>
    </>);
    const additionalBlock = <>
        <Button label="войти" />
    </>;

    return (<TypicalHeader logoSrc={MrYarosLogo} headerLinks={headerLinks} additionalBlock={additionalBlock} />);
}