import React, {HTMLAttributes} from "react";
import ChartProvider from "./context/ChartContext";
import * as d3 from "d3";
import {LineNumberChartData} from "./context/@types/ChartData";
import {Line, MarginPropType} from "./components/Line";
import {ChartAxisX, ChartAxisY} from "./components/ChartAxis";
import {ChartGridX, ChartGridY} from "./components/ChartGrid";
import {ChartDot} from "./components/ChartDot";
import {ChartArea} from "./components/ChartArea";

type LineChartProps = {
    header?: string;
    description?: string;
    data: Array<LineNumberChartData>;
    axisX?: boolean;
    axisY?: boolean;
    gridX?: boolean;
    gridY?: boolean;
    useDot?: boolean;
    addArea?: boolean;
    curve?: boolean; //надо сгладить углы?
    width?: number;
    height?: number;
    margin?: MarginPropType;
} & HTMLAttributes<HTMLDivElement>;

export const LineChart = React.memo(function Chart({data, axisX, axisY, gridX, gridY, useDot, addArea, margin, className, ...pp}: LineChartProps) {
    const x = d3.scaleLinear()
        .domain(d3.extent(data, d => d[0]).map(value => value!!));

    const max = d3.max(data, d => +d[1])!!;
    const min = d3.min(data, d => +d[1])!!;
    const indent = (max-min) * 0.1;
    const y = d3.scaleLinear()
        .domain([min - indent, max + indent]);

    return (
        <ChartProvider data={data} scaleFuncX={x} scaleFuncY={y} {...pp}>
            {gridX !== false && <ChartGridX />}
            {gridY !== false && <ChartGridY />}
            {axisX !== false && <ChartAxisX />}
            {axisY !== false && <ChartAxisY />}
            <Line />
            {addArea !== false && <ChartArea />}
            {useDot !== false && <ChartDot />}
        </ChartProvider>
    )
})