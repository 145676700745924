import {makeSvgIconFromPath} from "./SvgIcons";
import React from "react";

export const IconFrown = makeSvgIconFromPath(
    "frown",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 16C16 16 14.5 14 12 14C9.5 14 8 16 8 16" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 9H9.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 9H15.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconSmile = makeSvgIconFromPath(
    "smile",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 9H9.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 9H15.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);

export const IconMeh = makeSvgIconFromPath(
    "meh",
    <>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 15H16" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 9H9.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 9H15.01" stroke="#0050E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </>
);