import React, {DetailsHTMLAttributes, useState} from "react";
import "./tooltip.scss";
import {TextS} from "../../text/Text/Text";

type Props = {
    tooltip: React.ReactNode;
} & DetailsHTMLAttributes<HTMLDivElement>;

export const Tooltip = React.memo(function Tooltip({tooltip, children, className, ...pp}: Props) {
    const [hover, setHover] = useState<boolean>(false);
    return (
        <div
            className="tooltip"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            {...pp}
        >
            {children}

            <div className="tooltip-body">
                {hover &&
                    <TextS className="margin-no">
                        {tooltip}
                    </TextS>
                }
            </div>
        </div>
    );
});